import Component from '@ember/component';
import { action } from '@ember/object';

export default class AccountLoginChallengeForgotpassword extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @action
  newPassword() {
    this.onNewPassword(this.email.toLowerCase());
  }
}
