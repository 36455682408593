import Component from '@ember/component';
import { className } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import OrderService from '../../services/order';
import AuthService from '../../services/auth';
import { UserRoleEnum } from '../../services/api-client';

export default class AppConfiguratorBar extends Component.extend({
  queryParams: ['step'],
  step: null
  // anything which *must* be merged to prototype here
}) {
  @service('order') orderService!: OrderService;
  @service('auth') authService!: AuthService;
  @service() calculation: any;
  @service() amplify: any;
  @service() router: any;

  currentOrder: any;

  next: any;
  isLoading: boolean = false;

  isPopupVisible = false;

  @computed('currentOrder.moduldefinition.width')
  get breiteIsValid() {
    if (this.authService.currentUser !== undefined) {
      if (
        this.authService.currentUser.role === UserRoleEnum.Admin ||
        this.authService.currentUser.role === UserRoleEnum.Advanced
      ) {
        return true;
      }
    }

    return (
      this.get('currentOrder.moduldefinition.width' as keyof this) >=
        this.get('currentOrder.cabinet.minWidth' as keyof this) &&
      this.get('currentOrder.moduldefinition.width' as keyof this) <
        +this.get('currentOrder.cabinet.maxWidth' as keyof this) + 1
    );
  }

  @computed('currentOrder.moduldefinition.height')
  get hoeheIsValid() {
    if (this.authService.currentUser !== undefined) {
      if (
        this.authService.currentUser.role === UserRoleEnum.Admin ||
        this.authService.currentUser.role === UserRoleEnum.Advanced
      ) {
        return true;
      }
    }
    return (
      this.get('currentOrder.moduldefinition.height' as keyof this) >=
        this.get('currentOrder.cabinet.minHeight' as keyof this) &&
      this.get('currentOrder.moduldefinition.height' as keyof this) <
        +this.get('currentOrder.cabinet.maxHeight' as keyof this) + 1
    );
  }

  @computed('currentOrder.moduldefinition.length')
  get laengeIsValid() {
    if (this.authService.currentUser !== undefined) {
      if (
        this.authService.currentUser.role === UserRoleEnum.Admin ||
        this.authService.currentUser.role === UserRoleEnum.Advanced
      ) {
        return true;
      }
    }
    return (
      this.get('currentOrder.moduldefinition.length' as keyof this) >=
        this.get('currentOrder.cabinet.minLength' as keyof this) &&
      this.get('currentOrder.moduldefinition.length' as keyof this) <
        +this.get('currentOrder.cabinet.maxLength' as keyof this) + 1
    );
  }

  @computed('currentOrder.name')
  get NameIsValid() {
    return this.get('currentOrder.name.length' as keyof this) >= 1;
  }

  @action
  showColorPickPopup() {
    if (!this.disabledFlag && this.disabledFlagStep4) {
      this.set('isPopupVisible', true);
    }
  }

  @action
  async setDefaultColor() {
    this.set('currentOrder.finish' as keyof this, 'Grobstruktur glänzend');
    this.set('currentOrder.color' as keyof this, {
      code: '7035',
      name: 'Light grey',
      rgb_approx: '215-215-215',
      rgb_hex: '#D7D7D7'
    });
    this.closePopup();
    await this.router.transitionTo('konfigurator', this.get('currentOrder.share' as keyof this), {
      queryParams: { step: '4' }
    });
  }

  @action
  closePopup() {
    this.set('isPopupVisible', false);
  }

  @computed('currentOrder.color', 'currentOrder.material')
  get colorIsValid() {
    return (
      this.get('currentOrder.color.code' as keyof this) != 'keine' ||
      this.get('currentOrder.material' as keyof this) == 'Edelstahl'
    );
  }

  @computed('disabledFlag', 'disabledFlagStep4', 'step')
  get nextDisabled() {
    return (
      (this.step == 1 && this.disabledFlag) ||
      (this.step == 2 && this.disabledFlag) ||
      (this.step == 3 && this.disabledFlagStep4)
    );
  }

  @action
  login() {
    if (!this.authService.isAuthenticated) {
      this.authService.toggleProperty('modal');
    }
  }

  @className('')
  @computed('breiteIsValid', 'hoeheIsValid', 'laengeIsValid', 'NameIsValid')
  get isNew() {
    if (
      !this.get('breiteIsValid') ||
      !this.get('hoeheIsValid') ||
      !this.get('NameIsValid') ||
      !this.get('laengeIsValid')
    ) {
      return 'isNew';
    } else {
      return 'isOld';
    }
  }

  @computed('breiteIsValid', 'hoeheIsValid', 'laengeIsValid', 'NameIsValid')
  get disabledFlag() {
    if (
      !this.get('breiteIsValid') ||
      !this.get('hoeheIsValid') ||
      !this.get('NameIsValid') ||
      !this.get('laengeIsValid')
    ) {
      return true;
    } else {
      return false;
    }
  }

  @computed('breiteIsValid', 'hoeheIsValid', 'laengeIsValid', 'NameIsValid', 'colorIsValid')
  get disabledFlagStep4() {
    if (
      !this.get('breiteIsValid') ||
      !this.get('hoeheIsValid') ||
      !this.get('NameIsValid') ||
      !this.get('laengeIsValid') ||
      !this.get('colorIsValid')
    ) {
      return true;
    } else {
      return false;
    }
  }

  @action
  async copyOrder() {
    this.set('isLoading', true);
    let newOrder = await this.orderService.createNewOrderFromExisting(this.currentOrder);
    this.set('isLoading', false);
    return this.get('router').transitionTo('konfigurator', newOrder?.share);
  }

  @action
  nextStep() {
    if (!this.nextDisabled) {
      this.next(...arguments);
    }
  }
}
