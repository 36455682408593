import Component from '@ember/component';
import {
  computed
} from '@ember/object';
import { inject as service } from '@ember/service';
import {
  isNone
} from '@ember/utils';
import {
  tagName
} from '@ember-decorators/component';
import AuthService from '../../services/auth';



@tagName('')

export default class AwsFile extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  

  @service amplify: any;
@service("auth") authService!: AuthService;


  level: string = 'private';

  @computed("file")
  get fileUrl() {

    if (isNone(this.file)) {
      return '';
    }

    return `https://fimab-filestorage.s3.eu-central-1.amazonaws.com/public/${this.file}`;
    
  }
}
