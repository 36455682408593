import { CubeSides, CabinetDefinition } from './types';

const cabinets: CabinetDefinition[] = [
  {
    id: 'cabinet-1',
    w: 1300,
    h: 800,
    d: 300,
    minW: 1100,
    maxW: 1600,
    minH: 275,
    maxH: 3000,
    minD: 180,
    maxD: 500,
    sides: [
      {
        type: CubeSides.FRONT,
        minX: 80,
        minY: 20,
        maxX: 1240,
        maxY: 780,
        width: 1300,
        height: 800,
        image: ''
      },
      {
        type: CubeSides.LEFT,
        minX: 40,
        minY: 20,
        maxX: 260,
        maxY: 780,
        width: 300,
        height: 800,
        image: ''
      },
      {
        type: CubeSides.RIGHT,
        minX: 40,
        minY: 20,
        maxX: 260,
        maxY: 780,
        width: 300,
        height: 800,
        image: ''
      },
      {
        type: CubeSides.TOP,
        minX: 60,
        minY: 40,
        maxX: 1240,
        maxY: 260,
        width: 1300,
        height: 300,
        image: ''
      },
      {
        type: CubeSides.BOTTOM,
        minX: 60,
        minY: 40,
        maxX: 1240,
        maxY: 260,
        width: 1300,
        height: 300,
        image: ''
      },
      {
        type: CubeSides.BACK,
        minX: 80,
        minY: 20,
        maxX: 1240,
        maxY: 780,
        width: 1300,
        height: 800,
        image: ''
      },
      {
        type: CubeSides.INNER,
        minX: 10,
        minY: 10,
        maxX: 1090,
        maxY: 590,
        width: 1100,
        height: 600,
        image: ''
      }
    ]
  },
  {
    id: 'cabinet-2',
    w: 1000,
    h: 600,
    d: 400,
    minW: 900,
    maxW: 1600,
    minH: 275,
    maxH: 2000,
    minD: 180,
    maxD: 500,
    sides: [
      {
        type: CubeSides.FRONT,
        minX: 80,
        minY: 20,
        maxX: 920,
        maxY: 580,
        width: 1000,
        height: 600,
        image: ''
      }
    ]
  }
];

export default cabinets;
