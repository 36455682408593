import User from 'fimab-konfigurator/model/user';

export default class Account {

  id: string
  name: string;
  email: string;
  taxId: string;
  phone: string;
  paymentId: number;
  shippingId: number;
  discount: number;
  city: string;
  street: string;
  zip: string;
  country: string;
  users: [User]|null;

  /**
   * @param data 
   */
  constructor(data: any) {
    const {
      id,
      name,
      email,
      taxId,
      phone,
      paymentId,
      shippingId,
      discount,
      city,
      street,
      zip,
      country,
      users
    } = data;

    this.id = id;
    this.name = name;
    this.email = email;
    this.taxId = taxId;
    this.phone = phone;
    this.paymentId = paymentId;
    this.shippingId = shippingId;
    this.discount = discount;
    this.city = city;
    this.street = street;
    this.zip = zip;
    this.country = country;
    if (users) {
      this.users = users.map((item: any)=> {
        return new User(item);
      })
    } else {
      this.users = null;
    }
  }
}
