import {
  computed
} from '@ember/object';

export default class User {

  id: string;
  cognitoUser: string;
  customerId: string;
  company: string;
  role: string;
  firstname: string;
  lastname: string;
  email: string;
  city: string;
  address: string;
  zip: string;
  country: string;
  avatar: Object;
  taxId: string;
  phone: string;
  salutation: string;

  constructor(data: any) {
    const {
      id,
      cognitoUser,
      customerId,
      role,
      company,
      firstname,
      lastname,
      email,
      city,
      address,
      zip,
      country,
      avatar,
      taxId,
      phone,
      salutation
    } = data;
    this.id = id
    this.cognitoUser = cognitoUser
    this.company = company
    this.customerId = customerId
    this.role = role
    this.firstname = firstname
    this.lastname = lastname
    this.email = email
    this.city = city
    this.address = address
    this.zip = zip
    this.country = country

    this.avatar = avatar
    this.taxId = taxId
    this.phone = phone
    this.salutation = salutation
    
   
    /*
    this.reports = reports.map((item: any) => {
      return new Report(item)
    });*/
  }
  @computed('firstname', 'lastname')
  get fullname() {
    const first = this.firstname;
    const last = this.lastname;

    return `${first} ${last}`;
  }
}
