import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { action } from '@ember/object';

@tagName('test')
export default class FormSelect extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  open: boolean = false;

  readonly = false;
  unit = '';

  options: string[] = [];

  // Selected option
  activeFilter: string = '';
  selectedIndex = 0;

  hoveredIndex = 0;

  async didReceiveAttrs() {
    this.set('selectedIndex', (await this.options).indexOf(this.activeFilter));
  }

  @action
  keyDown(event: KeyboardEvent) {
    if (!this.open) {
      return;
    }
    event.preventDefault();
    if (event.keyCode === 40 || (event.keyCode === 9 && !event.shiftKey)) {
      this.set('hoveredIndex', (this.hoveredIndex + 1) % this.options.length);
    }
    if (event.keyCode === 38 || (event.keyCode === 9 && event.shiftKey)) {
      if (this.hoveredIndex == 0) {
        this.set('hoveredIndex', this.options.length - 1);
      } else {
        this.set('hoveredIndex', (this.hoveredIndex - 1) % this.options.length);
      }
    }
    if (event.keyCode === 13 && this.open) {
      this.set('activeFilter', this.options[this.hoveredIndex]);
      this.set('selectedIndex', this.hoveredIndex);
      this.set('open', false);
    }
  }

  @action
  clickSelect() {
    if (this.readonly) return;
    this.toggleProperty('open');
  }

  @action
  setFilter(option: string, selectedIndex: number) {
    if (!this.open) return;
    this.set('selectedIndex', selectedIndex);
    if (option == 'Alle') {
      this.set('activeFilter', '');
    } else {
      this.set('activeFilter', option);
    }

    this.set('open', false);
  }
}
