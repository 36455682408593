import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import jQuery from 'jquery';
import AuthService from '../services/auth';
import { observes } from '@ember-decorators/object';
import OrderService from '../services/order';
import { Discount, Order, OrganizationInvite } from '../services/api-client';
import DiscountService from '../services/discount';
import OrganizationService from '../services/organization';

export default class Index extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service amplify: any;
  @service('auth') authService!: AuthService;
  @service('organization') orgService!: OrganizationService;
  @service('order') orderService!: OrderService;
  @service('discount') discountService!: DiscountService;
  @service toast: any;

  mydata?: any;
  deliverydate?: string;
  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;

  activeDiscount: Discount | undefined = undefined;
  activeDiscountModalOpen = false;

  pendingInviteModalOpen = false;
  pendingInvite: OrganizationInvite | undefined = undefined;

  constructor() {
    super(...arguments);
    this.initWhatever();

    this.getActiveDiscount();
    this.getPendingInvite();
  }

  async acceptInvite() {
    if (this.pendingInvite) {
      await this.orgService.organizationApi.organizationControllerAcceptInvite(this.pendingInvite.id);
      this.set('pendingInviteModalOpen', false);
      this.toast.success('Einladung angenommen');

      this.authService.trigger('nav:open');

      setTimeout(() => {
        this.authService.trigger('nav:highlight:company');
      }, 1500);
    }
  }

  async declineInvite() {
    if (this.pendingInvite) {
      await this.orgService.organizationApi.organizationControllerRejectInvite(this.pendingInvite.id);
      this.set('pendingInviteModalOpen', false);
      this.toast.success('Einladung abgelehnt');
    }
  }

  @observes('authService.currentUser')
  async getPendingInvite() {
    if (this.authService.currentUser == undefined) return;

    const invites = [this.orgService.getInvites('initial', 'pending'), this.orgService.getInvites('invite', 'pending')];
    const awaitedInvites = (await Promise.all(invites)).map((d) => d.data).flat();
    if (awaitedInvites && awaitedInvites.length > 0) {
      this.set('pendingInvite', awaitedInvites[0]);

      if (this.pendingInvite) {
        this.set('pendingInviteModalOpen', true);
      }
    }
  }

  async getActiveDiscount() {
    this.set('activeDiscount', (await this.discountService.discountApi.discountControllerActiveDiscount())?.data);

    if (
      this.activeDiscount &&
      localStorage.getItem(`armario_visited_discount_${this.activeDiscount.id}_${new Date().toLocaleDateString()}`) ==
        'true'
    ) {
      return;
    }

    if (this.activeDiscount) {
      this.set('activeDiscountModalOpen', true);
      localStorage.setItem(
        `armario_visited_discount_${this.activeDiscount.id}_${new Date().toLocaleDateString()}`,
        'true'
      );
    }
  }

  @observes('authService.isAuthenticated')
  async setOrders() {
    if (!this.authService.isAuthenticated) {
      return;
    }
    let orders = (await this.orderService.ordersApi.orderControllerFindMyOrders(7, 0))?.data?.orders;
    this.set('model', {
      orders: orders,
      lastOrder: orders?.slice(0, 1)[0],
      lastFinishedOrder: orders?.find((o: Order) => o.ordernumber != undefined),
      lastUnfinishedOrder: orders?.find((o: Order) => o.ordernumber == undefined)
    });
  }

  async initWhatever() {
    jQuery.ajax({
      url: 'https://www.fimab.eu/armario-changelog',
      type: 'GET',
      success: (data) => {
        this.set('mydata', jQuery(data).find('#page-content').html());
      }
    });
    jQuery(document).ready(function () {
      jQuery('.box-werbebanner').html('test');
    });
  }

  @action
  async deleteOrder(order: any) {
    try {
      await this.orderService.ordersApi.orderControllerDelete(order.id);
      const orders = (await this.orderService.ordersApi.orderControllerFindMyOrders(7, 0))?.data?.orders;
      this.model = {
        orders: orders,
        lastOrder: orders?.slice(0, 1)[0],
        lastFinishedOrder: orders?.find((o: Order) => o.ordernumber != undefined),
        lastUnfinishedOrder: orders?.find((o: Order) => o.ordernumber == undefined)
      };
    } catch (e) {
      this.toast.error('Da ist etwas schief gegangen');
    }
  }

  @action
  openModal() {
    this.authService.toggleProperty('modal');
  }

  @action
  toogleStudie1() {
    jQuery('#fallstudie1').show();
    console.log(this.target);
  }
  @action
  toogleStudie2() {
    jQuery('#fallstudie2').show();
    console.log(this.target);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    index: Index;
  }
}
