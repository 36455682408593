import {
  computed
} from '@ember/object';

import File from 'fimab-konfigurator/model/file';

export default class Component {

  id: string;
  fimabId: number;
  category: string;
  innerWidth: number;
  innerHeight: number;
  innerDepth: number;
  innerDiameter: number;
  outerWidth: number;
  outerHeight: number;
  outerDepth: number;
  outerDiameter: number;
  models: [File]|null;

  constructor(data: any) {
    const {
      id,
      category,
      innerWidth,
      innerHeight,
      innerDepth,
      innerDiameter,
      outerWidth,
      outerHeight,
      outerDepth,
      outerDiameter,
      models,
      fimabId
    } = data;

    this.id = id
    this.fimabId = fimabId
    this.category = category
    this.innerWidth = innerWidth;
    this.innerHeight = innerHeight;
    this.innerDepth = innerDepth;
    this.innerDiameter = innerDiameter;
    this.outerWidth = outerWidth;
    this.outerHeight = outerHeight;
    this.outerDepth = outerDepth;
    this.outerDiameter = outerDiameter;
    if (models) {
      this.models = models.map((item: object)=> {
        return new File(item);
      })
    } else {
      this.models = null;
    }
    
  }

}
