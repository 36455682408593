import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import OrderService from '../services/order';
import AuthService from '../services/auth';
import { observes } from '@ember-decorators/object';
import Calculation from '../services/calculation';

// Default-Konfiguration fuer Development

export default class Konfigurator extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service('order') orderService!: OrderService;
  @service('auth') authService!: AuthService;
  @service('calculation') calculation!: Calculation;

  hour?: number;
  weekday?: number;
  daystoadd?: number;
  morgen?: string;
  dayplus: number = 0;

  agb = false;
  widerruf = false;

  isOrdering = false;
  successMessage = false;
  savingAddress = false;
  changePaymentAddress = false;
  changeShippingAddress = false;
  userHasOrdered = false;

  price?: any;

  @observes('authService.isAuthenticated', 'model.order')
  async calcPrice() {
    let price = await this.calculation.getCalculatedPrice(this.model.order);
    const priceObj: any = {};
    priceObj.cost = price;
    priceObj.price = price * this.model.order.amount;
    priceObj.netprice = (priceObj.price * 100) / 119;
    priceObj.tax = priceObj.price - priceObj.netprice;

    this.set('price', priceObj);
  }

  @action
  async saveAddress() {
    this.set('savingAddress', true);
    await this.orderService.saveOrder(this.model.order);
    this.set('savingAddress', false);
    this.set('changePaymentAddress', false);
    this.set('changeShippingAddress', false);
  }

  @action
  openModal() {
    this.authService.toggleProperty('modal');
  }

  @action
  async createOrder() {
    this.set('isOrdering', true);
    await this.orderService.createOrder(this.get('model.order'));
    this.set('isOrdering', false);
    this.set('userHasOrdered', true);
    this.set('successMessage', true);
  }

  constructor() {
    super(...arguments);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    Konfigurator: Konfigurator;
  }
}
