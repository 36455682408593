export default function () {
  // Add your transitions here, like:
  /*this.transition(
    this.fromRoute('account.login'),
    this.toRoute('account.profile'),
    this.use('toLeft'),
    this.reverse('toRight')
  );
  this.transition(
    this.fromRoute('account.register'),
    this.toRoute('account.login'),
    this.use('toLeft'),
    this.reverse('toRight')
  );
  this.transition(
    this.fromRoute('account.register'),
    this.toRoute('account.profile'),
    this.use('toLeft'),
    this.reverse('toRight')
  );
  this.transition(
    this.toRoute(['account.overview', 'account.profile', 'konfigurator']),
    this.use('fade'),
  );
  this.transition(
    this.childOf('.price'),
    this.use('toUp')
  );
  this.transition(
    this.fromRoute('*'),
    this.use('toLeft')
  )*/

}
