import Component from '@ember/component';
import { action } from '@ember/object';

export default class AccountLoginChallengeNewpassword extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @action
  newPassword() {

    if (this.password === this.password2) {
      this.onNewPassword(this.password);
    }
  }
};
