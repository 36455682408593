import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Calculation from '../services/calculation';
import OrderService from '../services/order';

export default class OrderconfirmationRoute extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('order') orderService!: OrderService;
  @service('calculation') calculation!: Calculation;

  constructor() {
    super(...arguments);
  }

  async model(params: any) {
    let order = await this.orderService.getLatestOrderByShare(params);

    if (order == undefined) {
      alert('Diese Konfiguration ist uns nicht bekannt oder es fehlt die Berechtigung diese einzusehen.');
      return;
    }

    if (!order.moduldefinition.alternateShipping) {
      order.moduldefinition.alternateShipping = {};
    }
    if (!order.moduldefinition.alternatePayment) {
      order.moduldefinition.alternatePayment = {};
    }
    return {
      order
    };
  }
}
