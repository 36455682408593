import Component from '@ember/component';
import { classNames } from '@ember-decorators/component';
import { inject as service} from '@ember/service';
import OrderService from 'fimab-konfigurator/order/service';

@classNames('view-controls-top')
export default class AppMetabar extends Component.extend({
  
}) {
  @service('order') orderService;
};
