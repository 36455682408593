import { helper } from '@ember/component/helper';

export function componentSize(params) {
  let object =  params[0];
  let width =  params[1];
  let height =  params[2];

  let widthToCompare = object.outerWidth > 0 ? object.outerWidth : object.innerWidth
  let heightToCompare = object.outerHeight > 0 ? object.outerHeight : object.innerHeight

  if (heightToCompare >= height) {
    return false;
  }
  if (widthToCompare >= width) {
    return false;
  }
  return true;
}

export default helper(componentSize);
