import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import OrderService from '../../services/order';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

@tagName('')
export default class DeliveryDate extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('order') orderService!: OrderService;

  @service intl!: IntlService;

  @computed('intl.locale')
  get locale() {
    return this.intl.get('locale')[0];
  }
}
