import { helper } from '@ember/component/helper';

export function modelShortName(params: any/*, hash*/) {
  const modelName: string = params[0];

  if(modelName == undefined) {
    return "";
  }
  
  const base = modelName.split(":")[0];

  return base + (modelName.toLowerCase().includes("rechts") ? " R":"")+ (modelName.toLowerCase().includes("links") ? " L":"");
}

export default helper(modelShortName);
