import Component from '@ember/component';
import { tagName, classNames } from '@ember-decorators/component';
import { action } from '@ember/object';

@tagName('div')
@classNames('inline-block')
export default class AppTooltip extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  visible = false;
  text = '';
  showIcon = true;

  @action
  mouseEnter() {
    this.set('visible', true);
  }
  @action
  mouseLeave() {
    this.set('visible', false);
  }
}
