import Service from '@ember/service';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Evented from '@ember/object/evented';
import AuthService from './auth';
import { ComponentApi } from './api-client';
import Component from '../model/component';
import { BASE_URL } from './baseurl';
export default class ComponentService extends Service.extend(Evented) {
  @service('auth') authService!: AuthService;

  @computed('authService.apiConfig')
  get componentApi() {
    return new ComponentApi(this.authService.apiConfig, BASE_URL);
  }

  constructor() {
    super(...arguments);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    component: Component;
  }
}
