import Service from '@ember/service';
import {
  AuthApi as AuthenticationApi,
  Configuration,
  ConfigurationParameters,
  LoginPayload,
  RegisterPayload,
  ResetPassswordPayload,
  UpdateUserPayload,
  User,
  UserApi
} from './api-client';
import { computed } from '@ember/object';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { inject as service } from '@ember/service';
import Evented from '@ember/object/evented';
import { Challenges } from '../components/account-login/component';
import { BASE_URL } from './baseurl';
export default class AuthService extends Service.extend(Evented) {
  api!: AuthenticationApi;
  userApi!: UserApi;
  token!: string | null;

  modal = false;

  @service toast: any;
  @service intl: any;

  resetEmail!: string;
  resetToken!: string;
  challenge: Challenges = Challenges.LOGIN;
  currentUser: User | undefined;

  constructor() {
    super(...arguments);

    const cacheLang = localStorage.getItem('armario_current_language');
    this.intl.setLocale([cacheLang ?? 'de-de']);

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.statusCode == 500) {
          this.toast.error('Da ist etwas schief gelaufen');
        } else {
          this.toast.error(`${error.response.data.statusCode}: ${error.response.data.message}`);
        }
        throw error;
      }
    );

    this.set('token', localStorage.getItem('fimab_token'));

    if (this.token !== null) {
      this.api = new AuthenticationApi(this.apiConfig, BASE_URL);
      this.userApi = new UserApi(this.apiConfig, BASE_URL);

      this.getUser();
    } else {
      this.userApi = new UserApi(undefined, BASE_URL);
      this.api = new AuthenticationApi(undefined, BASE_URL);
    }
  }

  @computed('token')
  get apiConfig() {
    if (this.token !== null) {
      const configParameters: ConfigurationParameters = {
        accessToken: this.token as string
      };
      return new Configuration(configParameters);
    } else {
      return new Configuration();
    }
  }

  logout() {
    localStorage.removeItem('fimab_token');
    this.set('token', null);
    this.set('currentUser', undefined);
  }

  async resetPassword(payload: ResetPassswordPayload) {
    const res = await this.api.authControllerResetPasswordWithToken(payload);
    localStorage.setItem('fimab_token', res.data.accessToken);
    this.set('token', res.data.accessToken);
    this.set('currentUser', res.data.user);
    this.trigger('auth:login');
    this.toast.success('Erfolgreich angemeldet');
  }

  async getUser() {
    try {
      if (!this.isAuthenticated) {
        return undefined;
      }
      if (this.currentUser != undefined) {
        return this.currentUser;
      }

      const user = (await this.api.authControllerGetLoggedInUser()).data;
      this.set('currentUser', user);

      return user;
    } catch (e) {
      return undefined;
    }
  }

  async login(payload: LoginPayload) {
    const res = await this.api.authControllerLogin(payload);
    if (res.status !== 200 && res.status !== 201) {
      throw res;
    }

    this.toast.success('Erfolgreich angemeldet');
    setTimeout(() => {
      this.set('modal', false);
    }, 10);

    const data = res.data;
    localStorage.setItem('fimab_token', data.accessToken);
    this.set('token', data.accessToken);
    this.set('currentUser', data.user);
    this.trigger('auth:login');
    return data;
  }

  async register(payload: RegisterPayload) {
    const res = await this.api.authControllerRegister(payload);
    if (res.status !== 200 && res.status !== 201) {
      throw res;
    }

    this.toast.success('Erfolgreich registriert');

    const data = res.data;
    localStorage.setItem('fimab_token', data.accessToken);
    this.set('token', data.accessToken);
    this.set('currentUser', data.user);
    this.trigger('auth:login');
    return data;
  }

  async updateUser(dto: UpdateUserPayload) {
    try {
      const res = await this.userApi.userControllerUpdate(dto);
      this.set('currentUser', res.data);
      return this.currentUser;
    } catch (e) {
      console.error(e);
      return this.currentUser;
    }
  }

  @computed('token')
  get isAuthenticated() {
    const token = this.token ? this.token : (localStorage.getItem('fimab_token') as string);
    if (token == null || token == undefined) {
      return false;
    }

    const decoded: any = jwt_decode(token);
    return new Date().getTime() <= decoded.exp * 1000;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    auth: AuthService;
  }
}
