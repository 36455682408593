import { helper } from '@ember/component/helper';
import { Order } from '../services/api-client';

export function orderstatusclasses(params: any/*, hash*/) {
  const order = params[0] as Order;
  return order.status === 'ORDERED' && order.ordernumber !== null
    ? 'badge badge-success'
    : order.status === 'NEU' && order.ordernumber !== null
    ? 'badge badge-purple'
    : 'badge badge-info'
}

export default helper(orderstatusclasses);
