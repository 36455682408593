import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import OrderService from '../services/order';
import AuthService from '../services/auth';

export default class Konfigurator extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('order') orderService!: OrderService;
  @service('auth') authService!: AuthService;

  constructor() {
    super(...arguments);
  }

  async model(params: any) {
    (window as any).fbq('track', 'CustomizeProduct');

    let resp = await this.orderService.getLatestOrderByShare(params);

    if (resp == undefined) {
      return this.transitionTo('konfiguratorinit');
    } else {
      return resp;
    }
  }
}
