import Component from '@ember/component';
import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';

@tagName('')
export default class AppColorconfig extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  showPanel: Boolean = false;

  @action
  togglePanel() {
    this.toggleProperty('showPanel');
  }
}
