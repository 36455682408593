import { ComponentDefinition } from './types';

const components: ComponentDefinition[] = [
  {
    id: '240096',
    name: 'Filterlüfter 105m³/h, Rittal',
    w: 185,
    h: 185,
    specSheetUrl: '240096.PDF',
    previewImageUrl: '240096_ISO.JPG',
    frontImageUrl: '240096_FRO.JPG'
  },
  {
    id: '240329',
    name: 'Drucktaster aus',
    w: 23,
    h: 25,
    specSheetUrl: '240329.PDF',
    previewImageUrl: '240329_ISO.JPG',
    frontImageUrl: '240329_FRO.JPG'
  },
  {
    id: 'macro1',
    name: 'Macro I',
    w: 100,
    h: 100,
    resizable: true,
    minW: 20,
    maxW: 2000,
    minH: 20,
    maxH: 4000,
  },
  {
    id: '240339',
    name: 'Hauptschalter, Baugröße 2',
    w: 71,
    h: 71,
    specSheetUrl: '240339.PDF',
    previewImageUrl: '240339_ISO.JPG',
    frontImageUrl: '240339_FRO.JPG'
  },
  {
    id: '240476',
    name: 'Hauptschalter, Baugröße 2',
    w: 130,
    h: 45,
    specSheetUrl: '240476.PDF',
    previewImageUrl: '240476_ISO.JPG',
    frontImageUrl: '240476_FRO.JPG'
  },
  {
    id: '240591',
    name: 'Kabeleinführung, icotek, KEL-ER-E5',
    w: 130,
    h: 25,
    specSheetUrl: '240591.PDF',
    previewImageUrl: '240591_ISO.JPG',
    frontImageUrl: '240591_FRO.JPG'
  }
];

export default components;
