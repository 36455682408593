import Service from '@ember/service';
import mutation from 'fimab-konfigurator/gql/history/createHistory';

export default class History {
  /**
   *
   * @param modelName
   * @param model
   * @param changeset
   * @param user
   */
  createHistoryEntry(modelName: string, model: string, changeset: object, user: string) {
    this.amplify.Logger.info('Try to generate History Entry');

    const variables = {
      data: {
        modelName: modelName,
        model: model,
        changeset: changeset,
        user: user
      }
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    history: History;
  }
}
