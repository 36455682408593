import Component from '@ember/component';
import { Organization } from '../../services/api-client';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { computed, action } from '@ember/object';
import { classNames } from '@ember-decorators/component';

@classNames('')
export default class CompanyResultCard extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;

  org!: Organization;

  // eslint-disable-next-line no-unused-vars
  onJoinRequest?: (_org: Organization) => void;

  @action
  joinCompany() {
    if (this.onJoinRequest) {
      this.onJoinRequest(this.org);
    }
  }

  @computed('org')
  get initials() {
    if (!this.org) return '';
    if (this.org?.name.includes(' ')) {
      return this.org.name.charAt(0).toUpperCase() + this.org?.name.charAt(1).toUpperCase();
    } else {
      return this.org.name.charAt(0).toUpperCase();
    }
  }
}
