import Service from '@ember/service';
import { inject as service } from '@ember/service';
import AuthService from './auth';
import { computed } from '@ember/object';
import { PriceConfigApi } from './api-client';
import { BASE_URL } from './baseurl';

export default class Calculation extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;

  @computed('authService.apiConfig')
  get priceApi() {
    return new PriceConfigApi(this.authService.apiConfig, BASE_URL);
  }

  currentPrice = 0;

  saveTimeout: any;
  async getCalculatedPrice(order: any) {
    return new Promise<number>((resolve) => {
      if (this.authService.isAuthenticated) {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(async () => {
          const res = await this.priceApi.priceconfigControllerPriceCalcNew(order.id);
          const cost = res.data;
          this.set('currentPrice', cost);
          resolve(cost);
        }, 250);
      } else {
        return resolve(0);
      }
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    calculation: Calculation;
  }
}
