import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';

export default class AccountMain extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;

  error?: object | null = null;

  step: number = 1;

  challenge: string = 'LOGIN';
  register = false;

  @action
  afterRegister(user: any) {
    this.set('register', false);
    this.set('authService.modal', false);
  }

  @action
  showRegister() {
    this.set('register', true);
  }

  @action
  hideRegister() {
    this.set('register', false);
  }

  @action
  onLogin() {
    this.set('authService.modal', false);
  }

  @action
  onHide() {
    this.set('authService.modal', false);
  }

  @action
  onError(error: object) {
    if (error.code == 'UsernameExistsException') {
      this.set('error', {
        message: 'Diese E-Mail-Adresse ist bereits vergeben'
      });
      this.set('step', 1);
    }
    if (error.code == 'UserNotConfirmedException') {
      this.set('error', {
        message: 'Dieser Account ist noch nicht aktiviert, wir haben Ihnen den Aktivierungscode erneut zugesandt.'
      });
      this.set('step', 3);
      this.send('showRegister');
    }
  }

  @action
  removeErrorMessage() {
    this.set('error', null);
  }
}
