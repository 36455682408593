import { inject as service } from "@ember/service";
import Account from "fimab-konfigurator/model/account";
import User from "fimab-konfigurator/model/user";
import Cabinet from "fimab-konfigurator/model/cabinet";
import File from "fimab-konfigurator/model/file";

import moment from "moment";
export default class Order {
  id: string;
  deleted: boolean;
  moduldefinition: Object;
  user: User | null;
  cabinet: Cabinet | null;
  exportXml: File | null;
  finish: string;
  share: string;
  material: string;
  color: Object;
  status: string;
  isPublic: Boolean;
  name: string;
  ordernumber: string;
  internalordernumber: string;
  screens: Object;
  count: number;
  s3screens: Object;
  createdAt: string;

  constructor(data: any) {
    const {
      id,
      deleted,
      moduldefinition,
      user,
      cabinet,
      finish,
      material,
      exportXml,
      color,
      share,
      isPublic,
      status,
      name,
      ordernumber,
      internalordernumber,
      screens,
      count,
      s3screens,
      createdAt,
    } = data;

    this.id = id;
    this.deleted = deleted;
    this.status = status;

    this.createdAt = moment(createdAt).format("DD.MM.YYYY");
    this.s3screens = s3screens;
    this.count = count;
    this.ordernumber = ordernumber;
    this.screens = screens;
    this.internalordernumber = internalordernumber;
    this.name = name;
    this.screens = screens;
    this.isPublic = isPublic;
    this.share = share;
    this.moduldefinition = moduldefinition;
    this.color = color;
    this.finish = finish;
    this.material = material;
    this.user = user ? new User(user) : null;
    this.cabinet = cabinet ? new Cabinet(cabinet) : null;
    this.exportXml = exportXml ? new File(exportXml) : null;
  }
}
