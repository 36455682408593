import Route from '@ember/routing/route';
import { computed } from '@ember/object';

export default class Account extends Route.extend({
  // anything which *must* be merged to prototype here
}) {

  myarr = [
    {test: 'foo'}
  ];

  firstname = 'test';
  lastname = 'test';

  @computed('firstname', 'lastname', 'myarr.@each.test')
  get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }
}
