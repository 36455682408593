import { inject as service } from '@ember/service';
import Service from '@ember/service';
import Evented from '@ember/object/evented';
import AuthService from './auth';
import { computed } from '@ember/object';
import { Layout, LayoutApi } from './api-client';
import { BASE_URL } from './baseurl';

export default class LayoutService extends Service.extend(Evented) {
  @service('auth') authService!: AuthService;
  layouts: Layout[] = [];

  constructor() {
    super(...arguments);
    console.log('init layout');
    this.getLayouts();
  }

  @computed('authService.apiConfig')
  get layoutApi() {
    return new LayoutApi(this.authService.apiConfig, BASE_URL);
  }

  async addLayout(name: string, components: any) {
    return this.layoutApi
      .layoutControllerCreate({
        name,
        components
      })
      .then((res) => {
        if (res.data != undefined) {
          this.set('layouts', [res.data, ...this.layouts]);
        }
        return this.layouts;
      });
  }

  async deleteLayout(layout: Layout) {
    return this.layoutApi.layoutControllerDelete(layout.id).then((res) => {
      if (res.data != undefined) {
        this.set(
          'layouts',
          this.layouts.filter((l) => l.id !== layout.id)
        );
      }
      return this.layouts;
    });
  }

  async getLayouts() {
    return this.layoutApi.layoutControllerFindMyLayouts().then((layouts) => {
      this.set('layouts', layouts.data);
      return this.layouts;
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    layout: Layout;
  }
}
