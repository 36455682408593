export enum DragModes {
  SMOOTH = 'smooth',
  SNAP = 'snap'
}

export enum ActionTypes {
  NONE = 'none',
  SELECT = 'select',
  DRAG = 'drag',
  RESIZE = 'resize',
  ALIGN = 'align'
}

export interface Point {
  x: number;
  y: number;
}

export interface Rect extends Point {
  w: number;
  h: number;
}

export interface ComponentDefinition {
  name: string;
  id: string;
  w: number;
  h: number;
  d?: number;
  r?: number;
  resizable?: boolean;
  minW?: number;
  minH?: number;
  maxW?: number;
  maxH?: number;
  specSheetUrl?: string;
  previewImageUrl?: string;
  frontImageUrl?: string;
}

export interface ComponentInstance extends ComponentDefinition, Point {
  element?: HTMLElement;
  componentId: string;
}

export enum DragAxisRestriction {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export interface DraggedComponentInstance {
  instance: ComponentInstance;
  offset?: Point;
  originX: number;
  originY: number;
  offsetX: number;
  offsetY: number;
  restrictAxis: boolean | DragAxisRestriction;
  others: ComponentInstance[]
}

export interface CreateDrag {
  data: ComponentDefinition;
  isDraggingObject?: boolean; // von ember-drag-drop verwendet
  offset: Point;
}

export enum CubeSides {
  TOP = 'OBEN',
  RIGHT = 'RECHTS',
  FRONT = 'VORNE',
  LEFT = 'LINKS',
  BOTTOM = 'UNTEN',
  BACK = 'HINTEN',
  INNER = 'MONTAGEPLATTE'
}

export interface CabinetSurfaceDefinition {
  type: CubeSides;
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  width: number;
  height: number;
  image: string;
}

export interface CabinetSurfaceConfiguration extends CabinetSurfaceDefinition {
  components: ComponentInstance[]
}

export enum CabinetMaterial {
  STAHLBLECH = 'stahlblech',
  EDELSTAHL = 'edelstahl'
}

export enum CabinetStructure {
  GLATTGLAENZEND = 'Glatt glänzend',
  GROBSTRUKTURGLAENZEND = 'Grobstruktur glänzend'
}

type Color = string;

export interface CabinetDefinition {
  id: string; // Interne Schrank ID
  w: number;
  h: number;
  d: number;
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
  minD: number;
  maxD: number;
  sides: CabinetSurfaceDefinition[];
}

export interface CabinetConfiguration extends CabinetDefinition {
  cabinetId: string; // ID der Schrank-Instanz
  sides: CabinetSurfaceConfiguration[];
  material: CabinetMaterial;
  structure: CabinetStructure;
  color: Color;
}

export enum AlignmentTypes {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  HorizontalCenter = 'horizontal-center',
  VerticalCenter = 'vertical-center'
}

export interface Line {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface Alignment extends Line {
  type: AlignmentTypes;
  delta: number;
  targetValue: number;
}

export enum KeyCodes {
  Backspace = 8,
  Enter = 13
}

export interface SelectionBox extends Rect {
  originX: number;
  originY: number
}

export interface Resize {
  w: number;
  h: number;
  originX: number;
  originY: number;
  instance: ComponentInstance;
  element: HTMLElement;
  others: ComponentInstance[];
}

export interface SnapCandidate extends Point {
  deltaX: number;
  deltaY: number;
}
