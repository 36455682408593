import { helper } from '@ember/component/helper';

export function initials(params: any /*, hash*/) {
  console.log('initials', params[0]);
  if (!params[0]) return 'AA';

  return ((params[0].firstName?.charAt(0) ?? '') + (params[0].lastName?.charAt(0) ?? '')).toUpperCase();
}

export default helper(initials);
