import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthService from '../services/auth';

export default class Application extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service amplify: any;
  @service('auth') authService!: AuthService;

  constructor() {
    super(...arguments);
  }

  model() {}
}
