// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:1bb349d4-1061-4a90-946f-f3832c627121",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_XqVR9usYo",
    "aws_user_pools_web_client_id": "43bv9k5ljcdui452l6k9qj39e0",
    "aws_user_files_s3_bucket": "fimabkonfigurator-imagestorage",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_content_delivery_bucket": "fimabkonfigurator-20181123151613--hostingbucket",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dle1w63amwbzt.cloudfront.net"
};


export default awsmobile;
