import Component from '@ember/component';
import { tagName, classNames, attribute } from '@ember-decorators/component';
import { computed, action } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';
import { Order } from '../../services/api-client';

@tagName('div')
@classNames('ral-color', 'pull-left')
export default class AppCabincolorsColor extends Component.extend({
  // anything which *must* be merged to prototype here
  classNameBindings: ['item.esd:esd-color']
}) {
  visible: boolean = false;
  item!: any;
  order!: Order;

  @service() amplify: any;

  // eslint-disable-next-line no-unused-vars
  onClick!: (item: any) => void;

  @attribute
  @computed('item')
  get style() {
    const hex = this.get('item.rgb_hex' as keyof this);
    return htmlSafe(`background: ${hex}; border: 1px solid rgba(29, 37, 59, 0.2);`);
  }

  @action
  click() {
    if (
      (this.order.status === 'ORDERED' && this.order.ordernumber !== null) ||
      (this.order.status === 'NEU' && this.order.ordernumber !== null)
    ) {
      return;
    }
    if (this.get('model.material' as keyof this) == 'Edelstahl') {
      return;
    }

    if (
      this.item.esd != undefined &&
      this.item.esd &&
      ((this.item.esd_structures.shiny_rough && this.order.finish == 'Glatt glänzend') ||
        (this.item.esd_structures.shiny_rough && this.order.finish == 'Grobstruktur glänzend'))
    ) {
      this.order.finish = '';
    }
    // $("#RALWahlText").html("RAL " + this.item.code + " : " + this.item.name);
    // $("#RALWahl").css({ "background-image" : "none" , "background-color" : this.item.rgb_hex });
    this.onClick(this.item);
    this.amplify.trigger('3DView:setColor', this.item);
  }

  @action
  mouseEnter() {
    this.set('visible', true);
  }
  @action
  mouseLeave() {
    this.set('visible', false);
  }
}
