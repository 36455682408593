import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { action } from '@ember/object';

export default class ProfileComponent extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;

  @action
  openModal() {
    this.authService.toggleProperty('modal');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'account/profile': ProfileComponent;
  }
}
