import Component from '@ember/component';
import { action } from '@ember/object';
import { ResetPassswordPayload } from '../../../services/api-client';
export default class AccountLoginChallengeSetnewpassword extends Component.extend({
  // anything which *must* be merged to prototype here
}) {

  // eslint-disable-next-line no-unused-vars
  onNewPassword!: (payload: ResetPassswordPayload) => {};

  @action
  newPassword() {
    this.onNewPassword({
      email: '',
      token: '',
      password: this.password,
      passwordConfirmation: this.password2
    });
  }
}
