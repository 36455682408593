import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';
import { run } from '@ember/runloop';
import { attribute } from '@ember-decorators/component';
import OrderService from '../../services/order';
import ComponentService from '../../services/component';

export default class AppConfiguration extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() amplify: any;
  @service('order') orderService!: OrderService;
  @service('component') componentService!: ComponentService;
  @service('dragdrop') draggingService: any;

  @attribute('style') style = 'height:100%;';

  gameInstance: any;

  step!: string;

  cleanEnv!: boolean;

  isCabinInitialized: boolean = false;

  isLoaded: boolean = false;
  showlochbilder: boolean = false;

  constructor() {
    super(...arguments);
    this.amplify.on('3DView:initWithCabin', this, 'initCabinTask');
    this.amplify.on('3DView:setColor', this, 'setColor');
    this.amplify.on('3DView:activateCamera', this, 'activateCamera');
    this.amplify.on('3DView:screenshot', this, 'screenshot');
    this.amplify.on('3DView:removeColor', this, 'removeColor');
    this.amplify.on('3DView:deactivateCamera', this, 'deactivateCamera');
    this.amplify.on('3DView:reset', this, 'reset3DView');
    this.initFocusHandler();
  }

  willDestroyElement() {
    this.amplify.off('3DView:setColor', this, 'setColor');
    this.amplify.off('3DView:initWithCabin', this, 'initCabinTask');
    this.amplify.off('3DView:screenshot', this, 'screenshot');
    this.amplify.off('3DView:removeColor', this, 'removeColor');
    this.amplify.off('3DView:activateCamera', this, 'activateCamera');
    this.amplify.off('3DView:reset', this, 'reset3DView');
    this.amplify.off('3DView:deactivateCamera', this, 'deactivateCamera');
    if (typeof (window as any).unityInstance.Quit == 'function') {
      (window as any).unityInstance.Quit(() => {
        return true;
      });
    }
  }

  reset3DView() {
    this.activateCamera();
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'reloadScene');
    }
  }

  screenshot() {
    setTimeout(() => {
      this.activateCamera();
      if (this.gameInstance) {
        console.log('makeScreenshot');

        this.gameInstance.SendMessage('GUI', 'makeScreenshot', '');
      }
    }, 250);
  }

  initCabinTask(data: any) {
    this.initCabin(data);
  }

  async screenshots(screens: any[]) {
    screens = screens.map((item: any) => {
      item.open = false;
      return item;
    });
    this.orderService.set('screens', screens);
  }

  async loadView() {
    this.set('cleanEnv', true);
    this.set('isLoaded', true);
    this.amplify.trigger('3DView:loaded');
    if (this.isCabinInitialized == false) {
      const { Breite, Hoehe, Laenge, Sockel, Cabin } = this.get('model.moduldefinition');

      if (this.step == '2' || this.step == '3') {
        this.activateCamera();
      }
      if (Breite > 0 && Hoehe > 0) {
        this.initCabin({ Breite, Hoehe, Laenge, Sockel, Cabin });
      }
    }
    return;
  }

  async baugruppen(payload: any) {
    const res = await this.componentService.componentApi.componentControllerFindAll(0, 100);
    const components = res.data;
    let bauteile = payload.Baugruppenteile.map((Baugruppenteil: any) => {
      let rauskommt = Object.assign({}, components.findBy('id', Baugruppenteil.id), Baugruppenteil);

      return rauskommt;
    });
    bauteile = bauteile.filter((teil: any) => {
      if (isEmpty(teil.id)) {
        return false;
      } else if (teil.X_Position == 0) {
        return false;
      }
      return true;
    });

    if (!isEmpty(this.model.moduldefinition)) {
      this.set('model.moduldefinition.assemblyparts', bauteile);
    }

    if (this.model.moduldefinition.assemblyparts.length > bauteile.length) {
      return;
    }

    this.set('model.moduldefinition.assemblyparts', bauteile);
  }

  didInsertElement() {
    window.addEventListener(
      'message',
      async (event) => {
        {
          if (event.origin == 'https://app.hubspot.com') return;
          if (event.data == 'loaded') {
            this.loadView();
            return;
          }
          try {
            if (typeof event.data != 'string' || event.data == 'debugger-client') {
              return;
            }
            const payload = JSON.parse(event.data);

            if (!isEmpty(payload.meshData) && payload.id == 'StlContainer') {
              const rawResponse = await fetch(
                'https://4gpuvheohl.execute-api.eu-central-1.amazonaws.com/prod/download',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    filetype: 'stl',
                    customer: `${this.authService.currentUser.customerId}`,
                    body: payload.meshData,
                    ordernumber: `${this.model.share}`
                  })
                }
              );
              const content = await rawResponse.json();

              let a = document.createElement('A') as any;
              a.href = content.url;
              a.download = content.url.substr(content.url.lastIndexOf('/') + 1);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            if (!isEmpty(payload.meshData) && payload.id == 'ObjContainer') {
              const rawResponse = await fetch(
                'https://4gpuvheohl.execute-api.eu-central-1.amazonaws.com/prod/download',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    filetype: 'obj',
                    customer: `${this.authService.currentUser.customerId}`,
                    body: payload.meshData,
                    ordernumber: `${this.model.share}`
                  })
                }
              );
              const content = await rawResponse.json();

              let a = document.createElement('A') as any;
              a.href = content.url;
              a.download = content.url.substr(content.url.lastIndexOf('/') + 1);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }

            if (!isEmpty(payload.type)) {
              this.orderService.set('screens', payload.images);
              this.set('model', this.get('orderService.order'));
            }

            if (!isEmpty(payload._Siteinfo)) {
              this.amplify.trigger('3DView:activeSide', payload._Siteinfo[0]);
              return;
            }
            if (!isEmpty(payload._Screens)) {
              await this.screenshots(payload._Screens);
              return;
            }
            if (!isEmpty(payload.Baugruppenteile)) {
              await this.baugruppen(payload);
              return;
            }
          } catch (e) {
            console.error(e, event);
          }
        }
      },
      false
    );
    /* const gameInstance = UnityLoader.instantiate("gameContainer", "/3dview/Build/fimab-3d-view.json", {
        Module: {
          onRuntimeInitialized: () => {
            
            this.gameInstance = gameInstance;
            window.gameInstance = gameInstance;
          },
        }
    });*/

    //const gameInstance = UnityLoader.instantiate("gameContainer", "/Build/fimab-3d-view.json", {onProgress: UnityProgress});
  }

  removeColor() {
    this.set('model.color', {
      code: 'keine',
      name: 'Light grey',
      rgb_approx: '215-215-215',
      rgb_hex: '#D7D7D7'
    });
    this.set('model.finish', '');
    this.orderService.saveOrder(this.get('model'));
  }

  async setColor(color: object) {
    this.set('model.color', color);
    if (this.get('model.finish') === '') {
      this.set('model.finish', 'Grobstruktur glänzend');
    }
    this.orderService.saveOrder(this.get('model'));
  }
  @action
  exportObj() {
    if ((window as any).unityInstance) {
      (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'SendObjContainer');
    }
  }
  @action
  exportStl() {
    if ((window as any).unityInstance) {
      (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'SendStlContainer');
    }
  }
  @action
  hideDebugNav() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'hideDebugNav');
    }
  }

  @action
  lochbilder() {
    if ((window as any).unityInstance) {
      if (this.showlochbilder) {
        (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'SetDisplayMode', 0);
      } else {
        (window as any).unityInstance.SendMessage('[CabinetConfigurator]', 'SetDisplayMode', 1);
      }
      this.toggleProperty('showlochbilder');
    }
  }

  showSperrflaechen: boolean = false;

  @action
  sperrflaeche() {
    if (this.gameInstance) {
      if (this.showSperrflaechen) {
        this.gameInstance.SendMessage('GUI', 'hideSperrflaeche');
      } else {
        this.gameInstance.SendMessage('GUI', 'showSperrflaeche');
      }
      this.toggleProperty('showSperrflaechen');
    }
  }

  @action
  standardansicht() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnSTART');
    }
  }

  @action
  turnOben() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnOBEN');
    }
  }
  @action
  turnUnten() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnUNTEN');
    }
  }
  @action
  turnLinks() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnLINKS');
    }
  }
  @action
  turnRechts() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnRECHTS');
    }
  }
  @action
  turnVorne() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnVORNE');
    }
  }
  @action
  turnHinten() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnHINTEN');
    }
  }
  @action
  turnMontageplatte() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'turnMONTAGEPLATTE');
    }
  }

  activateCamera() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'cameraON');
      this.gameInstance.SendMessage('GUI', 'FocusCanvas', '1');
    }
  }

  deactivateCamera() {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'cameraOFF');
      this.gameInstance.SendMessage('GUI', 'FocusCanvas', '0');
    }
  }

  async initCabin(config: object) {
    if (this.gameInstance) {
      this.gameInstance.SendMessage('GUI', 'reloadScene');
      this.set('isCabinInitialized', true);
      run.later(() => {
        this.activateCamera();
        this.gameInstance.SendMessage('GUI', 'hideDebugNav');
        this.gameInstance.SendMessage('GUI', 'setCorpus', JSON.stringify(config));
        if (typeof this.get('model.color.name') == 'string') {
          this.setColor(this.get('model.color'));
        }
        this.initBauteile();

        this.set('cleanEnv', false);
        if (['1', '4'].includes(this.get('step'))) {
          this.deactivateCamera();
        }
      }, 0);
    }
  }

  initBauteile() {
    const bauteile = this.get('model.moduldefinition.assemblyparts');
    if (!isNone(bauteile)) {
      bauteile.forEach((element: any) => {
        this.initBauteil(element);
      });
    }
  }

  initBauteil(element: any) {
    this.send('handleDrag', element);
  }

  initFocusHandler() {
    document.addEventListener('click', (e: any) => {
      if (this.gameInstance) {
        if (e.target.id == '#canvas') {
          this.gameInstance.SendMessage('GUI', 'FocusCanvas', '1');
        } else {
          this.gameInstance.SendMessage('GUI', 'FocusCanvas', '0');
        }
      }
    });
  }

  @action
  handleDrag(object: any) {
    if (isEmpty(object.id)) {
      return;
    }
    if (this.gameInstance) {
      this.amplify.trigger('makro:dropped');
      this.gameInstance.SendMessage('GUI', 'createProd', JSON.stringify(object));
    }
  }

  @action
  handleDrag2(object: object) {
    console.log(object, 'handleDrag2');
  }

  @action
  dragOverAction(event: Event) {
    event.preventDefault();
    let object = this.get('draggingService.currentItem');
    if (isEmpty(object.id)) {
      return;
    }
    if (this.gameInstance) {
      this.amplify.trigger('makro:dropped');
      this.gameInstance.SendMessage('GUI', 'createProd', JSON.stringify(object));
    }
  }
  @action
  dragOutAction() {}
}
