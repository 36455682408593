// app/validations/messages.js
export default {
    inclusion: '{description} is not included in the list',
    exclusion: '{description} is reserved',
    invalid: '{description} is invalid',
    confirmation: "Passwörter stimmen nicht überein",
    accepted: '{description} must be accepted',
    empty: "{description} can't be empty",
    blank: '{description} must be blank',
    present: "{description} ist ein Pflichtfeld",
    collection: '{description} must be a collection',
    singular: "{description} can't be a collection",
    tooLong: '{description} is too long (maximum is {max} characters)',
    tooShort: '{description} ist zu kurz, (Minimum ist {min} Zeichen)',
    between: '{description} must be between {min} and {max} characters',
    before: '{description} must be before {before}',
    onOrBefore: '{description} must be on or before {onOrBefore}',
    after: '{description} must be after {after}',
    onOrAfter: '{description} must be on or after {onOrAfter}',
    wrongDateFormat: '{description} must be in the format of {format}',
    wrongLength: '{description} is the wrong length (should be {is} characters)',
    notANumber: '{description} must be a number',
    notAnInteger: '{description} must be an integer',
    greaterThan: '{description} must be greater than {gt}',
    greaterThanOrEqualTo: '{description} must be greater than or equal to {gte}',
    equalTo: '{description} must be equal to {is}',
    lessThan: '{description} must be less than {lt}',
    lessThanOrEqualTo: '{description} must be less than or equal to {lte}',
    otherThan: '{description} must be other than {value}',
    odd: '{description} must be odd',
    even: '{description} must be even',
    positive: '{description} must be positive',
    multipleOf: '{description} must be a multiple of {multipleOf}',
    date: '{description} must be a valid date',
    email: 'E-Mail: muss eine valide E-Mail-Adresse sein',
    phone: '{description} must be a valid phone number',
    url: '{description} must be a valid url'
  }