import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { Order } from '../services/api-client';
import AuthService from '../services/auth';
import OrderService from '../services/order';

export default class Index extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  queryParams = {
    token: {
      refreshModel: true
    },
    challenge: {
      refreshModel: true
    },
    email: {
      refreshModel: true
    }
  };
  @service() amplify: any;
  @service('auth') authService!: AuthService;
  @service('order') orderService!: OrderService;
  @service intl: any;

  beforeModel() {
    const cacheLang = localStorage.getItem('armario_current_language');
    this.intl.setLocale([cacheLang ?? 'de-de']);
  }

  constructor() {
    super(...arguments);
  }

  async model(params: any) {
    if (params.challenge != undefined) {
      this.authService.set('challenge', (params.challenge as string).toUpperCase());
      this.authService.set('resetToken', params.token);
      this.authService.set('resetEmail', params.email);
      this.authService.set('modal', true);
    }

    (window as any).fbq('track', 'ViewContent');
    if (!this.authService.isAuthenticated) {
      return;
    }

    let orders = (await this.orderService.ordersApi.orderControllerFindMyOrders(7, 0))?.data?.orders;
    return {
      orders: orders,
      lastOrder: orders?.slice(0, 1)[0],
      lastFinishedOrder: orders?.find((o: Order) => o.ordernumber != undefined),
      lastUnfinishedOrder: orders?.find((o: Order) => o.ordernumber == undefined)
    };
  }
}
