import Controller from '@ember/controller';

export default class AccountNewpassword extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  queryParams: string[] = ['code', 'username', 'email', 'clientId'];
  code?: string =  null;
  username?: string =  null;
  email?: string =  null;
  clientId?: string =  null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'account/newpassword': AccountNewpassword;
  }
}
