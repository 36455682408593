import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import AuthService from '../services/auth';
import OrganizationService from '../services/organization';
import { computed, action } from '@ember/object';
import {
  CreateOrganizationPayload,
  Organization,
  OrganizationInvite,
  OrganizationInviteReasonEnum
} from '../services/api-client';
import { observes } from '@ember-decorators/object';
import { validateFormat } from 'ember-changeset-validations/validators';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

export default class CompanyController extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;
  @service('organization') orgService!: OrganizationService;

  inviteFilter = ['pending'];
  requestFilter = ['pending'];

  search = '';
  organizationResults = [];

  leaveCompanyConfirmOpen = false;
  invites = undefined;

  inviteModalOpen = false;

  inviteValidations = {
    email: [
      validateFormat({
        type: 'email'
      })
    ]
  };
  inviteChangeset: any = new Changeset({}, lookupValidator(this.inviteValidations), this.inviteValidations);

  constructor() {
    super(...arguments);
  }

  @action
  openInviteModal() {
    this.set('inviteModalOpen', true);
  }

  @action
  closeInviteModal() {
    this.set('inviteModalOpen', false);
  }

  @action
  inviteUser() {
    this.inviteChangeset.validate();

    if (!this.inviteChangeset.get('isValid')) return;

    if (!this.orgService?.currentOrganization?.id) return;

    this.orgService.inviteUser(this.inviteChangeset.get('email'));
    this.set('inviteModalOpen', false);
  }

  @observes('authService.currentUser')
  async userChanged() {
    if (this.authService.currentUser) {
      const invites = await this.orgService.getInvites('register', 'pending');

      if (invites) {
        this.set('invites', invites.data);
      }
    } else {
      this.set('invites', undefined);
    }
  }

  @computed('orgService.currentOrganization', 'requestFilter', 'requestFilter.[]')
  get pendingRequests() {
    return this.orgService.currentOrganization?.invites.filter(
      (invite) => this.requestFilter.includes(invite.status) && invite.reason !== 'initial'
    );
  }

  @computed('orgService.currentOrganization', 'inviteFilter', 'inviteFilter.[]')
  get pendingInvites() {
    return this.orgService.currentOrganization?.invites.filter(
      (invite) =>
        this.inviteFilter.includes(invite.status) && (invite.reason === 'initial' || invite.reason === 'invite')
    );
  }

  @action
  createOrganization(payload: CreateOrganizationPayload) {
    this.orgService.createOrganization(payload);
  }

  @action
  async joinOrganization(org: Organization) {
    await this.orgService.requestOrganizationJoin(org);
    const invites = await this.orgService.getInvites(OrganizationInviteReasonEnum.Register);
    this.set(
      'invites',
      invites.data.filter((i) => i.status === 'pending')
    );
  }

  @action
  openLeaveCompanyConfirm() {
    this.set('leaveCompanyConfirmOpen', true);
  }

  @action
  leaveCompany() {
    this.set('leaveCompanyConfirmOpen', false);
    this.orgService.leaveOrganization();
  }

  @action
  toggleInviteFilter(filter: string) {
    if (this.inviteFilter.includes(filter)) {
      this.inviteFilter.removeAt(this.inviteFilter.findIndex((f) => f === filter));
    } else {
      this.inviteFilter.pushObject(filter);
    }
  }

  @action
  toggleRequestFilter(filter: string) {
    if (this.requestFilter.includes(filter)) {
      this.requestFilter.removeAt(this.requestFilter.findIndex((f) => f === filter));
    } else {
      this.requestFilter.pushObject(filter);
    }
  }

  @action
  async acceptInvite(invite: OrganizationInvite) {
    await this.orgService.organizationApi.organizationControllerAcceptInvite(invite.id);
    this.orgService.loadOrganization();
  }

  @action
  async rejectInvite(invite: OrganizationInvite) {
    await this.orgService.organizationApi.organizationControllerRejectInvite(invite.id);
    this.orgService.loadOrganization();
  }

  timeout = 0;

  @observes('search')
  async findOrganizations() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.set(
      'timeout',
      setTimeout(async () => {
        console.log(this.search);
        if (!this.search || this.search.length < 5) {
          this.set('organizationResults', []);
          return;
        }

        const res = await this.orgService.organizationApi.organizationControllerFindOrganizations(this.search);
        this.set('organizationResults', res.data);
      }, 400)
    );
  }
}
