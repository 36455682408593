/* eslint-disable no-unused-vars */
import Component from "@ember/component";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { Router } from "@ember/routing";
import AuthService from "../../services/auth";
import { AxiosError } from "axios";
import { ResetPassswordPayload } from "../../services/api-client";

export enum Challenges {
  LOGIN = "LOGIN",
  NEWPASSWORD = "NEWPASSWORD",
  NEWPASSWORDSEND = "NEWPASSWORDSEND",
  FORGOTPASSWORD ="FORGOTPASSWORD",
}

export default class AccountLogin extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  password: string = "";
  email: string = "";
  forgotPasswordEmail: string = "";

  @service amplify: any;
  @service("auth") authService!: AuthService;
  @service router?: Router;

  isLoading: any;
  showPasswordReset: boolean = false;
  showForgotPassword: boolean = false;

  showRegister!: () => {};
 
  error?: object | null = null;
  success?: object | null = null;

  user?: object | null = null;
  newPasswordRequired: boolean = false;

  @action
  async signIn() {
    this.set("isLoading", true);

    try {
      await this.authService.login({email: this.email,password: this.password});
      await this.afterLoginSuccess();
      this.onLogin();
    } catch(e) {
      this.handleNotAuthorizedException();
    }

    // try {
    //   let user = await this.amplify.Auth.signIn(this.email, this.password);
    //   if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    //     this.handleNewPasswordRequired(user);
    //   } else {
    //     await this.afterLoginSuccess();
    //     this.amplify.trigger("account:login", user);
    //     this.onLogin();
    //   }
    // } catch (err) {
    //   console.log(err);
    //   this.set("isLoading", false);
    //   if (err.code === "UserNotConfirmedException") {
    //     this.handleUserNotConfirmedException();
    //   } else if (err.code === "PasswordResetRequiredException") {
    //     this.handlePasswordResetRequiredException();
    //   } else if (err.code === "NotAuthorizedException") {
    //     this.handleNotAuthorizedException();
    //   } else if (err.code === "UserNotFoundException") {
    //     this.handleUserNotFoundException();
    //   } else {
    //     console.error(err);
    //   }
    // }
  }

  async handleNewPasswordRequired(user: any) {
    this.set("user", user);
    this.set("challenge", "NEWPASSWORD");
  }

  @action
  async handleNewPassword(newPassword: string) {
    // this.amplify.Auth.completeNewPassword;
    // await this.amplify.Auth.completeNewPassword(
    //   this.user, // the Cognito User Object
    //   newPassword, // the new password
    //   {
    //     email: this.email,
    //   }
    // );
    // this.set("challenge", "LOGIN");
    // this.set("success", {
    //   message: "Ihr Passwort wurde neu gesetzt. Sie können sich nun einloggen.",
    // });
  }

  handleUserNotFoundException() {
    this.set("error", {
      message:
        "Dieser Account ist bei ARMARIO nicht hinterlegt. Bitte registrieren Sie sich zuerst.",
    });
  }

  handleNotAuthorizedException() {
    this.set("error", {
      message:
        "Das Passwort und/oder die E-Mail Adresse sind falsch oder Sie haben Ihr Passwort zu oft falsch eingegeben. Bitte versuchen Sie es in 5 Minuten erneut.",
    });
  }

  handlePasswordResetRequiredException() {
    // this.set("error", {
    //   message: `Das Passwort muss zurückgesetzt werden. Wir haben Ihnen Anweisungen an Ihr hinterlegtes E-Mail-Postfach (${this.email}) gesendet.`,
    // });
    // this.amplify.Auth.forgotPassword(this.email)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));
  }
  handleUserNotConfirmedException() {
    // this.amplify.Auth.resendSignUp(this.email)
    //   .then(() => {
    //     this.set("error", {
    //       message: `Ihr Account wurde noch nicht aktiviert. Wir haben Ihnen den Aktivierungslink erneut an (${this.email}) gesendet!`,
    //     });
    //   })
    //   .catch((e) => {
    //     console.error(e);
      // });
  }

  @action
  createAccount() {
    this.showRegister();
  }

  async afterLoginSuccess() {
    this.onLogin();
    this.set("isLoading", false);
    return;
  }

  @action
  async setNewPassword(payload: ResetPassswordPayload) {
    this.set("isLoading", true);
    try {
      payload.email = this.authService.resetEmail;
      payload.token = this.authService.resetToken;
      await this.authService.resetPassword(payload);
      this.authService.set("challenge", Challenges.LOGIN);
      this.authService.set("modal", false);
    } catch(e) {
      const error = e as AxiosError;
      this.set("error", {
        message: (error.response?.data as any).message
      });
    }
    this.set("isLoading", false);
  }

  @action
  async requestNewPassword(email: string) {
    this.set("isLoading", true);
    try {
      await this.authService.api.authControllerForgotPassword({email});
      this.authService.set("challenge", Challenges.NEWPASSWORDSEND);
      this.removeErrorMessage();
    } catch(e) {
      const error = e as AxiosError;
      this.set("error", {
        message: (error.response?.data as any).message
      });
    }
    this.set("isLoading", false);
  }

  @action
  forgotPassword() {
    this.set("authService.challenge", Challenges.FORGOTPASSWORD);
  }

  @action
  showLogin() {
    this.set("authService.challenge", Challenges.LOGIN);
  }

  @action
  removeErrorMessage() {
    this.set("error", null);
    this.set("success", null);
  }
}
