import Component from '@ember/component';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { action, computed } from '@ember/object';
import FileService from '../../services/file';

export default class AccountProfile extends Component.extend({}) {
  @service('history') history!: History;
  @service('auth') authService!: AuthService;
  @service('file') fileService!: FileService;

  file?: File;
  isLoading: any;

  didReceiveAttrs() {}

  @computed('authService.currentUser')
  get model() {
    return this.authService.currentUser;
  }

  constructor() {
    super(...arguments);
  }

  @action
  async fileUpload(data: any) {
    if (this.authService.currentUser == undefined) return;
    const file: File = data.target.files[0];
    const base64 = this.arrayBufferToBase64(await file.arrayBuffer());
    await this.uploadImage(base64, `${this.authService.currentUser?.id}/${file.name}`, file);
  }

  arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  async uploadImage(imageBase64: any, key: string, file: File) {
    if (this.authService.currentUser == undefined) return;
    let imageData = await fetch(`data:image/jpg;base64,${imageBase64}`);
    let blobData = await imageData.blob();
    await this.fileService.fileApi.fileControllerCreate({
      contentType: blobData.type,
      data: imageBase64,
      key: key,
      level: 'public',
      size: blobData.size
    });

    this.authService.currentUser.avatar = {
      name: file.name,
      type: 'avatar',
      size: blobData.size,
      contentType: blobData.type,
      level: 'public',
      key: key
    };
    this.saveUser(this.authService.currentUser);
  }

  @action
  // eslint-disable-next-line no-unused-vars
  async removeFile(_file: any) {
    if (this.authService.currentUser == undefined) return;
    this.authService.currentUser.avatar = null;
    await this.authService.updateUser(this.authService.currentUser);
  }

  @action
  async saveUser(data: any) {
    this.set('isLoading', true);
    await this.authService.updateUser(data);

    setTimeout(() => {
      this.set('isLoading', false);
    }, 300);
  }
}
