import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  validatePresence,
  validateLength,
  validateConfirmation,
  validateFormat
} from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import { task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import AuthService from '../../services/auth';

export default class AccountRegister extends Component.extend({}) {
  @service amplify: any;
  @service('auth') authService!: AuthService;
  @service router: any;

  showLogin!: () => {};

  userSub: string = '';

  errorText = '';

  userValidations = {
    email: validateFormat({
      type: 'email',
      message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein'
    }),
    firstname: [validatePresence({ presence: true, message: 'Bitte geben Sie Ihren Vornamen ein' })],
    lastname: [validatePresence({ presence: true, message: 'Bitte geben Sie Ihren Nachnamen ein' })],
    password: [
      validateLength({
        min: 8,
        message: 'Das Passwort muss mindestens 8 Zeichen lang sein'
      })
    ],
    address: [validatePresence({ presence: true, message: 'Bitte geben Sie Ihre Adresse ein' })],
    zip: [validatePresence({ presence: true, message: 'Bitte geben Sie Ihre Postleitzahl ein' })],
    salutation: [validatePresence({ presence: true, message: 'Bitte wählen Sie eine Anrede' })],
    city: [validatePresence({ presence: true, message: 'Bitte geben Sie Ihre Stadt ein' })],
    country: [validatePresence({ presence: true, message: 'Bitte geben Sie Ihr Land ein' })]
  };

  newUser = {
    email: '',
    password: '',
    passwordConfirmation: '',
    title: 'Herr',
    salutation: 'Herr',
    firstname: '',
    lastname: '',
    company: '',
    city: '',
    country: '',
    zip: '',
    address: '',
    privacy: false,
    taxId: '',
    department: 'keine'
  };

  departments: string[] = ['keine', 'Einkauf', 'Vertrieb', 'Konstruktion', 'Planung', 'Geschäftsführung', 'Sonstige'];

  constructor() {
    super(...arguments);
    let model = this.get('newUser');
    this.changeset = new Changeset(model, lookupValidator(this.userValidations), this.userValidations);
  }

  @action
  changeDepartment() {
    console.log(...arguments);
  }

  @action
  changeSalutation() {
    console.log(...arguments);
  }

  @action
  onShowLogin() {
    this.showLogin();
  }

  @action
  removeErrorMessage() {
    this.set('errorText', '');
  }

  @action
  async submitForm(newUser: any) {
    // this.registerNewUser.perform(newUser);
    this.changeset.validate();

    if (this.changeset.get('password') !== this.changeset.get('passwordConfirmation')) {
      this.changeset.addError('passwordConfirmation', 'Passwörter stimmen nicht überein');
    }

    if (!this.changeset.get('isValid')) return;

    await this.authService.register({
      email: newUser.get('email').toLowerCase(),
      password: newUser.get('password'),
      passwordConfirmation: newUser.get('passwordConfirmation'),
      firstName: newUser.get('firstname'),
      lastName: newUser.get('lastname'),
      street: newUser.get('address'),
      city: newUser.get('city'),
      country: newUser.get('country'),
      zip: newUser.get('zip'),
      company: newUser.get('company'),
      taxId: newUser.get('taxId'),
      salutation: newUser.get('salutation')
    });
    this.set('step', 1);
    this.onSuccess();
  }
  @action
  focusElement() {
    //this.$(event.target).parent('.input-group').addClass("input-group-focus")
  }
  @action
  unFocusElement(event: object) {
    //this.$(event.target).parent('.input-group').removeClass("input-group-focus")
  }
}
