import Component from '@ember/component';
import { observes } from '@ember-decorators/object';
import { computed, action, set } from '@ember/object';

import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import OrderService from '../../services/order';
import CabinetService from '../../services/cabinet';
import { Cabinet, UserRoleEnum } from '../../services/api-client';

export default class AppCabinDimensions extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  mode: boolean = true;

  @service router: any;
  @service amplify: any;
  @service('auth') authService!: AuthService;
  @service('order') orderService!: OrderService;
  @service('cabinet') cabinetService!: CabinetService;
  montageplatte_size?: string;

  firstOptionChange = true;
  optionalOptionModal = false;

  constructor() {
    super(...arguments);
  }

  @observes('model.ceilingOption', 'model.socketOption', 'model.interiorHingesOption', 'model.socketHeightOption')
  optionChange() {
    if (this.firstOptionChange) {
      this.set('optionalOptionModal', true);
    }

    this.set('firstOptionChange', false);
  }

  @computed()
  get cabinets() {
    return this.cabinetService.cabinetApi.cabinetControllerFindAll().then((data) => data.data);
  }

  isValidNumber(input: any) {
    let regex = /^\d+$/;

    return regex.test(input);
  }

  @computed('model.moduldefinition.width')
  get breiteIsValid() {
    if (this.authService.currentUser !== undefined) {
      if (
        this.authService.currentUser.role === UserRoleEnum.Admin ||
        this.authService.currentUser.role === UserRoleEnum.Advanced
      ) {
        return true;
      }
    }
    return (
      this.isValidNumber(this.get('model.moduldefinition.width')) &&
      this.get('model.moduldefinition.width') >= this.get('model.cabinet.minWidth') &&
      this.get('model.moduldefinition.width') < this.get('model.cabinet.maxWidth') + 1
    );
  }
  @computed('model.moduldefinition.height')
  get hoeheIsValid() {
    if (this.authService.currentUser !== undefined) {
      if (
        this.authService.currentUser.role === UserRoleEnum.Admin ||
        this.authService.currentUser.role === UserRoleEnum.Advanced
      ) {
        return true;
      }
    }
    return (
      this.isValidNumber(this.get('model.moduldefinition.height')) &&
      this.get('model.moduldefinition.height') >= this.get('model.cabinet.minHeight') &&
      this.get('model.moduldefinition.height') < this.get('model.cabinet.maxHeight') + 1
    );
  }
  @computed('model.moduldefinition.length')
  get laengeIsValid() {
    if (this.authService.currentUser !== undefined) {
      if (
        this.authService.currentUser.role === UserRoleEnum.Admin ||
        this.authService.currentUser.role === UserRoleEnum.Advanced
      ) {
        return true;
      }
    }
    return (
      this.isValidNumber(this.get('model.moduldefinition.length')) &&
      this.get('model.moduldefinition.length') >= this.get('model.cabinet.minLength') &&
      this.get('model.moduldefinition.length') < this.get('model.cabinet.maxLength') + 1
    );
  }
  @computed('model.name')
  get NameIsValid() {
    return this.get('model.name.length') >= 1;
  }

  didReceiveAttrs() {
    if (this.stepsDone.step1) {
      set(this, 'mode', false);
    }
    run.later(() => {
      if (this.get('laengeIsValid') && this.get('hoeheIsValid') && this.get('breiteIsValid')) {
        this.set('showNext', true);
      }
    }, 100);
  }

  isValid() {
    return true;
  }

  @observes('model.moduldefinition.width', 'model.moduldefinition.height', 'model.moduldefinition.length', 'model.name')
  onChange() {
    if (this.get('laengeIsValid') && this.get('hoeheIsValid') && this.get('breiteIsValid') && this.get('NameIsValid')) {
      this.set('showNext', true);
    }
    this.orderService.saveOrder(this.get('model'));
    return true;
  }

  @computed('model', 'model.ordernumber')
  get isOrderReadOnly() {
    return (
      (this.model.ordernumber !== undefined && this.model.ordernumber !== null && this.model.status == 'ORDERED') ||
      (this.model.status === 'NEU' && this.model.ordernumber !== null) ||
      this.model.moduldefinition.cabin == 4
    );
  }

  @computed('model')
  get isCabin4() {
    return this.model.moduldefinition.cabin == 4;
  }

  @computed('model', 'model.ordernumber')
  get isCabin4OrReadonly() {
    return (
      (this.model.ordernumber !== undefined && this.model.ordernumber !== null && this.model.status == 'ORDERED') ||
      (this.model.status === 'NEU' && this.model.ordernumber !== null) ||
      this.isCabin4
    );
  }

  @observes('model.socketOption', 'model.ceilingOption', 'model.interiorHingesOption', 'model.socketHeightOption')
  onOptionChange() {
    this.orderService.saveOrder(this.get('model'));
    return true;
  }

  @computed('model.moduldefinition.width', 'model.moduldefinition.height', 'model.moduldefinition.length', 'model.name')
  get montagesizeBreite() {
    if (this.get('model.moduldefinition.width') < 1 || this.get('model.cabinet.id') == 'cjq857brf00pe0744sjw8x1p2') {
      return 0;
    }
    if (this.get('model.cabinet.id') == 'cjq857a6500pb0744f2r3yza8') {
      return this.get('model.moduldefinition.width') - 80;
    }
    return this.get('model.moduldefinition.width') - 80;
  }

  @computed('model.moduldefinition.width', 'model.moduldefinition.height', 'model.moduldefinition.length', 'model.name')
  get montagesizeHoehe() {
    if (this.get('model.moduldefinition.height') < 1 || this.get('model.cabinet.id') == 'cjq857brf00pe0744sjw8x1p2') {
      return 0;
    }
    if (this.get('model.cabinet.id') == 'cjq857a6500pb0744f2r3yza8') {
      return this.get('model.moduldefinition.height') - 34;
    } else {
      return this.get('model.moduldefinition.height') - 30;
    }
  }

  @action
  resetKonfiguration() {
    this.set('mode', true);
    this.set('showNext', false);
    this.set('stepsDone', {});

    this.router.transitionTo('konfiguratorinit');
  }

  @action
  async selectCabinet(cabinet: Cabinet) {
    this.toggleProperty('mode');
    this.set('model.cabinet', cabinet);

    if (cabinet.migrationId == 'cjq856wif00p507443n0jvtlq') {
      this.set('model.moduldefinition.cabin', 1);
      this.set('model.moduldefinition.modelKey', 'model1_right');
    }
    if (cabinet.migrationId == 'cjq85767h00p80744srj85e7a') {
      this.set('model.moduldefinition.cabin', 2);
      this.set('model.moduldefinition.modelKey', 'model1_left');
    }
    if (cabinet.migrationId == 'cjq857a6500pb0744f2r3yza8') {
      this.set('model.moduldefinition.cabin', 3);
      this.set('model.moduldefinition.modelKey', 'model_2');
    }
    if (cabinet.migrationId == 'cjq857brf00pe0744sjw8x1p2') {
      this.set('model.moduldefinition.cabin', 4);
      this.set('model.moduldefinition.modelKey', 'model_3');
    }
    await this.orderService.saveOrder(this.get('model'));

    this.router.transitionTo('konfigurator', this.get('model'));
  }

  @action
  toggleDetail() {
    this.toggleProperty('mode');
  }
}
