import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Order } from '../services/api-client';
import AuthService from '../services/auth';
import OrderService from '../services/order';
import { computed } from '@ember/object';
import { observes } from '@ember-decorators/object';

export default class Konfiguratoroverview extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('router') router: any;
  @service('auth') authService!: AuthService;
  @service('order') orderService!: OrderService;

  @service() toast!: any;

  shareModalOpen = false;
  shareOrder: Order | undefined = undefined;
  shareMail = '';
  deleteConfirmOpen = false;
  orderToDelete?: Order = undefined;

  readonly MAX_PAGES = 3;
  currentPage = 1;
  limit = 50;

  searchTerm = '';
  orderStatusFilter = '';

  count = 0;

  @computed('count')
  get pageCount() {
    return Math.ceil(this.count / 50) + 1;
  }

  @computed('currentPage')
  get offset() {
    return 50 * (this.get('currentPage') - 1);
  }

  constructor() {
    super(...arguments);
    this.refetchOrders();
  }

  @action
  nextPage() {
    if (this.get('currentPage') >= this.get('pageCount') - 1) return;
    this.set('currentPage', this.get('currentPage') + 1);
    this.refetchOrders();
  }

  @action
  prevPage() {
    if (this.get('currentPage') <= 1) return;
    this.set('currentPage', this.get('currentPage') - 1);
    this.refetchOrders();
  }

  @action
  setPage(page: number) {
    if (isNaN(page)) return;
    this.set('currentPage', +page);
    this.refetchOrders();
  }

  @computed('pageCount', 'currentPage')
  get pageList() {
    const pages: (string | number)[] = [];
    for (let i = 1; i < this.get('pageCount'); i++) {
      if (i < this.get('currentPage') - this.MAX_PAGES) {
        if (pages.includes('...')) continue;
        pages.push('...');
        continue;
      }

      if (i > this.get('currentPage') + this.MAX_PAGES) {
        pages.push('...');
        break;
      }

      pages.push(i);
    }
    return pages;
  }

  @action
  hideShareModal() {
    this.set('shareModalOpen', false);
  }

  @action
  showShareModal(order: Order) {
    this.set('shareOrder', order);
    this.set('shareModalOpen', true);
  }

  @action
  async share() {
    if (!this.shareOrder) return;
    this.orderService.shareOrderWith(this.shareMail, this.shareOrder);
    this.hideShareModal();
  }

  @action
  async deleteOrder(order: any) {
    this.set('orderToDelete', order);
    this.set('deleteConfirmOpen', true);
  }

  @observes('orderStatusFilter')
  async searchOrFilterChanged() {
    console.log('searchOrFilterChanged');

    this.set('currentPage', 1);
    this.refetchOrders();
  }

  @action
  async refetchOrders() {
    const res = (
      await this.orderService.ordersApi.orderControllerFindMyOrders(
        this.get('limit'),
        this.get('offset'),
        true,
        this.get('searchTerm'),
        this.get('orderStatusFilter')
      )
    )?.data;

    this.set('model', res.orders);
    this.set('count', res.count);
  }

  @action
  async confirmDelete() {
    try {
      if (!this.orderToDelete) return;
      await this.orderService.ordersApi.orderControllerDelete(this.orderToDelete.id);
      this.refetchOrders();
    } catch (e) {
      this.toast.error('Da ist etwas schief gegangen');
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    konfiguratoroverview: Konfiguratoroverview;
  }
}
