import Component from '@ember/component';
import { classNames, attribute } from '@ember-decorators/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Router } from '@ember/routing';



@classNames('sidebar')
export default class AppSidebar extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @attribute("data") colorScheme: String = 'red';

  @service() router: any;
  @service() amplify: any;

  @action
  goToKonfigurator() {
    this.router.transitionTo('konfigurator')
  }
};
