import Component from '@ember/component';
import { action } from '@ember/object';

export default class AppConfirmModal extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  open = false;

  headerTitle = 'Bestätigen';
  confirmLabel = 'OK';
  abortLabel = 'Abbrechen';

  size = 'md';
  closeOnConfirm = true;

  onConfirm?: () => void;
  onAbort?: () => void;

  @action
  confirm() {
    console.log(this.closeOnConfirm);

    if (this.closeOnConfirm) {
      this.set('open', false);
    }

    if (this.onConfirm) {
      this.onConfirm();
    }
  }

  @action
  abort() {
    this.set('open', false);
    if (this.onAbort) {
      this.onAbort();
    }
  }
}
