import Service from '@ember/service';
import { set } from '@ember/object';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Evented from '@ember/object/evented';
import AuthService from './auth';
import {
  Order,
  OrderApi,
  CreateOrderPayload,
  CreateOrderConfirmationPayload,
  Moduldefinition,
  UtilityApi
} from './api-client';
import AmplifyService from '../amplify/service';
import Calculation from './calculation';
import { v4 } from 'uuid';
import FileService from './file';
import { observes } from '@ember-decorators/object';
import { BASE_URL } from './baseurl';

export default class OrderService extends Service.extend(Evented) {
  @service('calculation') calculation!: Calculation;
  @service('amplify') amplify!: AmplifyService;
  @service('auth') authService!: AuthService;
  @service('file') fileService!: FileService;

  // Deliverydate
  daystoadd: number = 0;
  weekday: number = 0;
  hour: number = 0;
  morgen: string = '';
  dayplus: number = 0;

  saving = false;

  screens: undefined | any[] = undefined;

  stepsDone = {
    step1: false,
    step2: false,
    step3: false
  };

  @computed('authService.apiConfig')
  get ordersApi() {
    return new OrderApi(this.authService.apiConfig, BASE_URL);
  }

  @computed('authService.apiConfig')
  get utilityApi() {
    return new UtilityApi(this.authService.apiConfig, BASE_URL);
  }

  order!: Order;

  constructor() {
    super(...arguments);
    this.authService.on('auth:login', this, 'saveOrderForUser');
  }

  private _deliveryDateCached?: Promise<string>;
  private _deliveryTextCached?: Promise<{ note: string; noteEN: string }>;
  private _deliveryDateSavedAt?: Date;

  get deliveryDate() {
    if (this._deliveryDateSavedAt && this._deliveryDateSavedAt.getHours() < 16 && new Date().getHours() >= 16) {
      this._deliveryDateSavedAt = new Date();
      const request = this.utilityApi.utilityControllerGetDeliveryDate();
      this._deliveryDateCached = request.then((d) => d.data.date ?? (d.data as unknown as string));
      this._deliveryTextCached = request.then((d) => ({ note: d.data.note, noteEN: d.data.noteEN }));
    } else if (this._deliveryDateCached !== undefined) {
      return { date: this._deliveryDateCached, text: this._deliveryTextCached };
    } else {
      this._deliveryDateSavedAt = new Date();
      const request = this.utilityApi.utilityControllerGetDeliveryDate();
      this._deliveryDateCached = request.then((d) => d.data.date ?? (d.data as unknown as string));
      this._deliveryTextCached = request.then((d) => ({ note: d.data.note, noteEN: d.data.noteEN }));
    }
    return { date: this._deliveryDateCached, text: this._deliveryTextCached };
  }

  private _holidaysCached?: Promise<string[]>;
  private _holidaysSavedAt?: Date;

  get holidays() {
    if (this._holidaysSavedAt && this._holidaysSavedAt.getHours() < 16 && new Date().getHours() >= 16) {
      this._holidaysSavedAt = new Date();
      this._holidaysCached = this.utilityApi.utilityControllerGetHolidays().then((d) => d.data);
    } else if (this._holidaysCached !== undefined) {
      return this._holidaysCached;
    } else {
      this._holidaysSavedAt = new Date();
      this._holidaysCached = this.utilityApi.utilityControllerGetHolidays().then((d) => d.data);
    }
    return this._holidaysCached;
  }

  async getLatestOrderByShare(params: any): Promise<Order | undefined> {
    if (params.konfigurator_share !== undefined) {
      this.set('order', (await this.ordersApi.orderControllerFindByShare(params.konfigurator_share)).data);
      return this.order;
    }
    return undefined;
  }

  async shareOrderWith(email: string, order: Order) {
    try {
      await this.ordersApi.orderControllerSharewith(order.id, { email });
    } catch (e) {
      console.log(e);
    }
  }

  async setOrderStatus(order: Order, newStatus: string) {
    if (order.id) {
      if (this.authService.isAuthenticated) {
        await this.ordersApi.orderControllerUpdateMyOrder({
          id: order.id,
          status: newStatus,
          ordernumber: order.ordernumber
        });
      } else {
        await this.ordersApi.orderControllerUpdateGuestOrder(order.id, {
          id: order.id,
          status: newStatus,
          ordernumber: order.ordernumber
        });
      }
    }
    return order;
  }

  async uploadImage(screen: any, imageBase64: any, order: Order) {
    let key = `OrderScreens/${order.id}/${screen}.jpg`;
    let imageData = await fetch(`data:image/jpg;base64,${imageBase64}`);
    let blobData = await imageData.blob();
    await this.fileService.fileApi.fileControllerCreate({
      contentType: blobData.type,
      data: imageBase64,
      key: key,
      level: 'public',
      size: blobData.size
    });
  }

  async createNewOrderFromExisting(existingOrder: Order, reOrder = false) {
    if (!existingOrder) {
      console.error('Error saving new Order');
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...newModul } = existingOrder.moduldefinition;

    let order: CreateOrderPayload = {
      share: v4(),
      name: `${existingOrder.name} (Kopie)`,
      color: existingOrder.color,
      isPublic: false,
      material: existingOrder.material,
      finish: existingOrder.finish,
      status: '',
      price: existingOrder.price,
      amount: existingOrder.amount,
      cabinet: existingOrder.cabinet,
      internalOrdernumber: existingOrder.internalOrdernumber,
      orderNote: existingOrder.orderNote,
      moduldefinition: {
        ...newModul,
        meta: {
          oldShare: existingOrder.share,
          oldId: existingOrder.id,
          oldUser: existingOrder.user != null ? existingOrder.user.id : null
        }
      } as Moduldefinition,
      user: this.authService.currentUser
    };

    if (reOrder) {
      order.ordernumber = existingOrder.ordernumber;
      order.status = existingOrder.status;
      order.s3screens = existingOrder.s3screens;
    }

    try {
      let savedOrder: Order = (await this.ordersApi.orderControllerCreate(order)).data;
      return savedOrder;
    } catch (e) {
      console.error('Error saving new Order', e);
    }

    return undefined;
  }

  async reOrder(existingOrder: Order) {
    if (!existingOrder) {
      console.error('Error saving new Order');
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...newModul } = existingOrder.moduldefinition;

    let order: CreateOrderPayload = {
      share: v4(),
      name: `${existingOrder.name} (Kopie)`,
      color: existingOrder.color,
      isPublic: false,
      ordernumber: existingOrder.ordernumber,
      orderNote: existingOrder.orderNote,
      material: existingOrder.material,
      finish: existingOrder.finish,
      status: existingOrder.status,
      price: existingOrder.price,
      amount: existingOrder.amount,
      cabinet: existingOrder.cabinet,
      moduldefinition: {
        ...newModul,
        meta: {
          oldShare: existingOrder.share,
          oldId: existingOrder.id,
          oldUser: existingOrder.user != null ? existingOrder.user.id : null
        }
      } as Moduldefinition,
      user: this.authService.currentUser
    };

    try {
      let savedOrder: Order = (await this.ordersApi.orderControllerCreate(order)).data;
      return savedOrder;
    } catch (e) {
      console.error('Error saving new Order', e);
    }

    return undefined;
  }

  async saveOrderForUser() {
    if (this.order != undefined && this.authService.currentUser != undefined) {
      this.order.user = this.authService.currentUser;
      await this.saveOrder(this.order);
    }
  }

  imagesUploaded = 0;

  @observes('screens')
  async uploadScreens() {
    if (this.screens != undefined && this.authService.isAuthenticated) {
      this.order.s3screens = {};
      const promises = [];
      for (const s of this.screens) {
        promises.push(this.uploadImage(s.id, s.imageBase64, this.order));
      }
      await Promise.all(promises);

      const keys: any = {};
      for (const screen of this.screens) {
        keys[screen.id] = `OrderScreens/${this.order.id}/${screen.id}.jpg?${new Date().getTime()}`;
      }
      this.set(`order.s3screens` as keyof this, keys);
      await this.saveOrder();
      this.set('imagesUploaded', this.imagesUploaded + 1);
    }
  }

  saveTimeout: any;
  async saveOrder(order: Order | undefined = undefined, timeout = true) {
    if (order === undefined) {
      order = this.order;
    }

    if (!Object.keys(order?.moduldefinition?.alternatePayment ?? {}).length) {
      order.moduldefinition.alternatePayment = null as any;
    }
    if (!Object.keys(order?.moduldefinition?.alternateShipping ?? {}).length) {
      order.moduldefinition.alternateShipping = null as any;
    }

    order.amount = +order.amount;

    clearTimeout(this.saveTimeout);
    if (timeout) {
      this.saveTimeout = setTimeout(async () => {
        this.set('saving', true);

        this.amplify.Cache.setItem('order', this.order);
        if (this.authService.isAuthenticated) {
          this.set('order', (await this.ordersApi.orderControllerUpdateMyOrder(order as Order)).data);
        } else {
          this.set(
            'order',
            (await this.ordersApi.orderControllerUpdateGuestOrder((order as Order).id, order as Order)).data
          );
        }

        this.trigger('order:saved');
        setTimeout(() => {
          this.set('saving', false);
        }, 500);
        return;
      }, 350);
    } else {
      this.amplify.Cache.setItem('order', this.order);
      if (this.authService.isAuthenticated) {
        this.set('order', (await this.ordersApi.orderControllerUpdateMyOrder(order as Order)).data);
      } else {
        this.set(
          'order',
          (await this.ordersApi.orderControllerUpdateGuestOrder((order as Order).id, order as Order)).data
        );
      }

      this.trigger('order:saved');
    }
  }

  async getNextOrderNumber() {
    return (await this.ordersApi.orderControllerGetOrdernumber()).data;
  }

  async createOrder(order: any) {
    if (isEmpty(order.ordernumber)) {
      let ordernumber = await this.getNextOrderNumber();
      set(order, 'ordernumber', ordernumber);
    }
    set(order, 'status', 'ORDERED');
    await this.saveOrder(order);
    // await this.setOrderStatus(order, "ORDERED");
    await this.exportXML(order);

    (window as any).fbq('track', 'Purchase');
    return order;
  }

  async createOrderConfirmation(payload: CreateOrderConfirmationPayload) {
    const res = await this.ordersApi.orderControllerCreateOrderConfirmation(this.order.id, payload);
    this.set('order', res.data);
  }

  async cleanup() {
    this.amplify.Cache.removeItem('order');
    this.amplify.Cache.removeItem('stepsDone');

    this.set('stepsDone', {
      step1: false,
      step2: false,
      step3: false
    });
  }

  async exportXML(order: any) {
    let orderNote = order.moduldefinition.orderNote;
    if (orderNote == null || orderNote == undefined) {
      orderNote = '';
    }

    try {
      // const emailNotification = await fetch(
      //   "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
      //   {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       email: "intern",
      //       subject: `Order: ${order.ordernumber} / Kundenbezeichnung: ${order.name}`,
      //       content:
      //         `Neue Bestellung von ${user?.firstName} ${user?.lastName}<br>
      //     ${order.cabinet.name} - B ${order.moduldefinition.width} H ${
      //           order.moduldefinition.height
      //         } T ${order.moduldefinition.length}
      //     <br>
      //     Summe: ${Math.round(order.price * 100) / 100}<br>
      //     <br>
      //     <p>
      //     orderNote: <br>
      //       ${orderNote}
      //     </p>
      //     <br>` + order.moduldefinition.alternateShipping
      //           ? `
      //     Der Kunde hat folgende abweichende Daten angegeben:<br>
      //     <br>
      //     Lieferadresse:<br>
      //     Firma: ${order.moduldefinition.alternateShipping?.company}<br>
      //     ZIP: ${order.moduldefinition.alternateShipping?.zip}<br>
      //     Stadt: ${order.moduldefinition.alternateShipping?.city}<br>
      //     Straße: ${order.moduldefinition.alternateShipping?.address}<br>
      //     Ort: ${order.moduldefinition.alternateShipping?.city}<br>
      //     <br>
      //     Rechnungsadresse:<br>
      //     Firma: ${order.moduldefinition.alternatePayment?.company}<br>
      //     ZIP: ${order.moduldefinition.alternatePayment?.zip}<br>
      //     Stadt: ${order.moduldefinition.alternatePayment?.city}<br>
      //     Straße: ${order.moduldefinition.alternatePayment?.address}<br>
      //     Ort: ${order.moduldefinition.alternatePayment?.city}<br>
      //     `
      //           : "",
      //     }),
      //   }
      // );
      // // Mail an den Kunden
      // const emailNotificationCustomer = await fetch(
      //   "https://h35roba4mf.execute-api.eu-west-1.amazonaws.com/prod/order",
      //   {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       email: order.user.email,
      //       cc: order.moduldefinition.orderBuyer,
      //       subject: `Vielen Dank für Ihre Bestellung: ${order.ordernumber} / Interne Bezeichnung: ${order.name}`,
      //       content: `Sehr geehrte/r ${order.user.salutation} ${order.user.lastname}<br>
      //     Vielen Dank für Ihre Bestellung auf ARMARiO. <br><br>Diese wird von unserem Team noch kurz geprüft. Eine detailierte Auftragsbestätigung erhalten Sie am nächsten Arbeitstag und Sie dürfen sich schon jetzt auf ihren individuellen Schaltschrank freuen.
      //     <br>Technische Daten: <a href="https://www.fimab.eu/technische-daten" target="_blank">https://www.fimab.eu/technische-daten</a><br><br><br>
      //     Freundliche Grüße aus dem Nordschwarzwald,<br>
      //     Ihr ARMARiO Team
      //     <br><br><br><br>
      //     -------------------<br>
      //     ARMARiO ist eine Marke der FiMAB.<br>
      //     FiMAB GmbH & Co. KG<br>
      //     Bühlstraße 8<br>
      //     75387 Neubulach<br>
      //     Telefon: +49 7053 96839-0<br>
      //     Telefax: +49 7053 96839-19<br>
      //     E-Mail: info@fimab.eu<br>
      //     http://www.fimab.eu/<br>
      //     Geschäftsführer: Markus Fiedler<br>
      //     Registergericht Amtsgericht Stuttgart, HRB 330801<br>
      //     Nach der Prüfung durch die AV wird aus unserem ERP eine qualifizierte Auftragsbestätigung versendet.
      //     `,
      //     }),
      //   }
      // );
      // await emailNotification.text();
      // await emailNotificationCustomer.text();
    } catch (e) {
      console.log(e);
    }
    try {
      // const rawResponse = await fetch(
      //   "https://l24efwnud4.execute-api.eu-central-1.amazonaws.com/local/export",
      //   {
      //     method: "POST",
      //     headers: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       order: order,
      //       user: order.user,
      //     }),
      //   }
      // );
      // await rawResponse.text();
    } catch (e) {
      console.log(e);
    }
  }

  @computed('order.price', 'order')
  get newPrice() {
    return this.order.price;
  }

  @computed('order.strikePrice', 'order')
  get strikePrice() {
    return this.order.strikePrice;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    order: Order;
  }
}
