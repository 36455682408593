import Route from '@ember/routing/route';

export default class AccountProfileOverview extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
 model () {
   return [
     1,2,3,4,5,6,7,8,9,9,10
   ];
 }
}
