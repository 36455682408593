import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function codeline(params/*, hash*/) {
  return htmlSafe(params[0].replace(/&/g, "&amp;")
  .replace(/</g, "&lt;")
  .replace(/>/g, "&gt;")
  .replace(/"/g, "&quot;")
  .replace(/'/g, "&#039;")
  .replace(/\n/g, '<br>').replace(/\s+/g, '&nbsp;'));
}

export default helper(codeline);
