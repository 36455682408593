import Controller from '@ember/controller';
import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
export default class AccountConfirm extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service amplify: any;
  @service('auth') authService!: AuthService;
  @service router: any;

  queryParams: string[] = ['code', 'username', 'email', 'clientId'];
  code?: string = '';
  username?: string = '';
  email?: string = '';
  clientId?: string = '';
  constructor() {
    super(...arguments);
  }
  async onConfirm() {
    try {
    } catch (e) {
      console.log(e);
    }
  }

  @observes('code')
  confirmUser() {
    this.amplify.Auth.confirmSignUp(this.username, this.code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
      .then((data) => {
        //this.onConfirm();
        this.router.transitionTo('account.login', { queryParams: { success: 'Account erfolgreich aktiviert!' } });
      })
      .catch((err) => {
        this.router.transitionTo('account.login', { queryParams: { success: 'Account erfolgreich aktiviert!' } });
      });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'account/confirm': AccountConfirm;
  }
}
