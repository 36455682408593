import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { v4 as uuid } from 'uuid';
import AmplifyService from '../amplify/service';
import { CreateOrderPayload, Moduldefinition } from '../services/api-client';
import AuthService from '../services/auth';
import OrderService from '../services/order';

export default class KonfiguratorInit extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('amplify') amplify!: AmplifyService;
  @service('order') orderService!: OrderService;
  @service('auth') authService!: AuthService;

  constructor() {
    super(...arguments);
  }
  async createOrder() {
    try {
      let order: CreateOrderPayload = {
        share: uuid(),
        name: '',
        color: {
          code: 'keine',
          name: 'Light grey',
          rgb_approx: '215-215-215',
          rgb_hex: '#D7D7D7'
        },
        isPublic: false,
        material: 'Stahlblech',
        finish: '',
        status: '',
        amount: 1,
        moduldefinition: {
          width: '',
          length: '',
          height: '',
          socket: '',
          cabin: 1,
          sides: {}
        } as Moduldefinition
      };

      if (this.authService.isAuthenticated) {
        const createdOrder = (await this.orderService.ordersApi.orderControllerCreateMyOrder(order)).data;
        this.orderService.order = createdOrder;
        return createdOrder;
      } else {
        const createdOrder = (await this.orderService.ordersApi.orderControllerCreateOrder(order)).data;
        this.orderService.order = createdOrder;
        return createdOrder;
      }
    } catch (e) {
      console.error('Error saving new Order', e);
      return undefined;
    }
  }

  async beforeModel() {
    let order = await this.createOrder();

    if (order == undefined) {
      return;
    }
    return this.transitionTo('konfigurator', order.share);
  }
}
