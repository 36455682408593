import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthService from '../services/auth';
import OrderService from '../services/order';

export default class Konfiguratoroverview extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;
  @service('order') orderService!: OrderService;

  constructor() {
    super(...arguments);
  }

  async model() {
    if (!this.authService.isAuthenticated) {
      return;
    }
  }
}
