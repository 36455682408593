import Controller from '@ember/controller';

export default class AccountLogin extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  queryParams: string[] = ['success'];
  success = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'account/login': AccountLogin;
  }
}
