import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { Service as IntlService } from 'ember-intl';
import { UserRoleEnum } from '../../services/api-client';

export default class AppNav extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service intl!: IntlService;

  openSearchModal!: Function;
  supportModal?: boolean = false;
  public defaultimage?: string = 'default.jpg';
  public banner: any;

  hamburgerOpen = false;
  register = false;

  highlight = '';

  @service amplify: any;
  @service('auth') authService!: AuthService;
  @service router: any;

  constructor() {
    super(...arguments);
    this.initWhatever();

    this.authService.on('nav:open', () => {
      this.set('hamburgerOpen', true);
    });

    this.authService.on('nav:highlight:company', () => {
      this.set('highlight', 'company');
    });
    // this.set("banner", this.werbebanner());
  }

  @action
  setLang(lang: string) {
    localStorage.setItem('armario_current_language', lang);
    this.intl.setLocale([lang]);
  }

  @computed('authService.currentUser', 'authService.isAuthenticated')
  get isAdmin() {
    if (this.authService.currentUser !== undefined) {
      if (this.authService.currentUser.role === UserRoleEnum.Admin) {
        return true;
      }
    }
    return false;
  }

  async initWhatever() {}

  werbebanner() {
    return [];
  }

  @action
  toggleHamburger() {
    this.set('hamburgerOpen', !this.hamburgerOpen);
  }

  @action
  closeHamburger() {
    this.set('hamburgerOpen', false);
  }

  @action
  openModal() {
    this.openSearchModal();
  }

  @action
  loginModal() {
    this.set('authService.modal', true);
  }

  @action
  logout() {
    this.authService.logout();
  }

  @action
  konfigurator() {
    this.router.transitionTo('konfigurator');
  }
  @action
  overview() {
    this.router.transitionTo('konfiguratoroverview');
  }
  @action
  openSupportModal() {
    this.toggleProperty('supportModal');
  }
  @action
  onHide() {
    this.set('supportModal', false);
  }
  @action
  close() {
    this.set('authService.modal', false);
  }

  @action
  openChat() {
    try {
      (window as any)?.HubSpotConversations?.widget?.open();
    } catch (e) {
      // silent
    }
    this.set('authService.modal', false);
  }
}
