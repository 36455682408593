
export default class File {

  id: string
  name: string;
  type: string;
  size: string;
  contentType: string;
  key: string;

  constructor(data: any) {
    const {
      id,
      name,
      type,
      size,
      contentType,
      key
    } = data;

    this.id = id;
    this.name = name;
    this.type = type;
    this.size = size;
    this.contentType = contentType;
    this.key = key;
  }
}
