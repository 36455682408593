import { helper } from '@ember/component/helper';
import { Order } from '../services/api-client';

export function orderstatus(params: any/*, hash*/) {
  const order = params[0] as Order;
  return order.status === 'ORDERED' && order.ordernumber !== null
    ? 'BESTELLT'
    : order.status === 'NEU' && order.ordernumber !== null
    ? 'ANGEBOT'
    : 'OFFEN'
}

export default helper(orderstatus);
