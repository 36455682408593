import Service from '@ember/service';
import Amplify, {
  Auth,
  I18n,
  Analytics,
  Storage,
  Cache,
  Logger
} from 'aws-amplify'

import awsmobile from '../aws-exports'
import Evented from '@ember/object/evented';


export default class AmplifyService extends Service.extend(Evented) {
  Amplify = Amplify;
  Auth = Auth;
  I18n = I18n;
  Analytics = Analytics;
  
  Storage = Storage;
  
  Cache = Cache;
  Logger = new Logger('Application');

  constructor() {
    super(...arguments);
    (window as any).LOG_LEVEL = 'ERROR';
    this.Amplify.configure(awsmobile);
    this.Storage.configure({ level: 'private' });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    'amplify': AmplifyService;
  }
}
