import Service from '@ember/service';

export default class Dragdrop extends Service.extend({
  // anything which *must* be merged to prototype here
}) {
  currentItem?: any|null = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'dragdrop': Dragdrop;
  }
}
