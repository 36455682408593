import Component from "@ember/component";


export default class AnimatedNumber extends Component.extend({}) {
  
  prevNumber: number = 0;
  number!: number;
  decimals = 2;

  currentAnimation: number | undefined = undefined;
  private animateValue(start: number, end: number, duration: number) {
    this.prevNumber = this.number;
    let startTimestamp: number = 0;

    const step = (timestamp: any) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      this.element.innerHTML = this.toUINumber(
        progress * (end - start) + start,
      );
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    this.currentAnimation = window.requestAnimationFrame(step);
  }

  private toUINumber(number: number) {
    return number.toLocaleString('de-DE', {
      minimumFractionDigits: this.decimals,
      maximumFractionDigits: this.decimals,
    });
  }

  didUpdateAttrs(): void {
    if (this.currentAnimation != undefined) {
      window.cancelAnimationFrame(this.currentAnimation);
    }

    this.animateValue(
      this.prevNumber,
      this.number,
      400,
    );
  }

  didInsertElement(): void {
    this.element.innerHTML = this.toUINumber(this.number);
  }
}
