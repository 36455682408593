import Component from '@ember/component';
import { OrganizationInvite } from '../../services/api-client';
import { inject as service } from '@ember/service';
import AuthService from '../../services/auth';
import { computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';

@classNames('')
export default class CompanyInviteCard extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('auth') authService!: AuthService;

  invite!: OrganizationInvite;

  @computed('org')
  get initials() {
    if (!this.invite) return '';
    if (this.invite.organization?.name.includes(' ')) {
      return (
        this.invite.organization.name.charAt(0).toUpperCase() + this.invite.organization?.name.charAt(1).toUpperCase()
      );
    } else {
      return this.invite.organization.name.charAt(0).toUpperCase();
    }
  }
}
